<template>
  <div id="app" ref="app">
    <router-view></router-view>
    <!-- 语言切换 -->
    <!-- <div class="language_change_bg">
      <VueDragResize style="right: 0px;overflow: hidden;" :isActive="true" :isResizable="false" axis="y"
        :parentLimitation="true" w="auto" :y="100">
    <div :class="[active ? 'language_change activelang' : 'language_change']" @mouseenter="activeOpen"
      @mouseleave="activeclose">
      <img src="@/images/langug.png" alt="">
    </div>
    <transition name="fade">
      <div class="language_select" v-if="active" @mouseenter="activeOpen" @mouseleave="activeclose">
        <div class='language_select_item' @click="langChange('cn')">中文简体</div>
        <div class='language_select_item' @click="langChange('en')">Enghlish</div>
        <div class='language_select_item' @click="langChange('hk')">繁體中文</div>
      </div>
    </transition>
    </VueDragResize>
  </div> -->
  </div>
</template>

<script>
import VueDragResize from 'vue-drag-resize';
export default {
  name: 'app',
  components: {
    VueDragResize
  },
  data() {
    return {
      activeTimer: null,
      active: false,
      languageVal: this.$i18n.locale,
    }
  },
  methods: {
    //语言切换
    langChange(e) {
      localStorage.setItem('lang', e);
      this.$i18n.locale = e;
      window.location.reload()
    },
    activeOpen() {
      this.active = true;
      if (this.activeTimer) {
        clearTimeout(this.activeTimer);
      }
    },
    activeclose() {
      this.activeTimer = setTimeout(() => {
        this.active = false;
      }, 1000)
    },
  }
}
</script>

<style lang="less">
.vdr.active:before {
  display: none;
}

.language_change {
  pointer-events: auto;
  position: absolute;
  top: 15px;
  right: 0px;
  width: 20px;
  height: 20px;
  background-color: #EF5693;
  padding: 8px 4px 8px 12px;
  cursor: pointer;
  border-radius: 15px 0 0 15px;
  transition: all 300ms ease-out;

  img {
    width: 20px;
    height: 20px;
  }
}

.activelang {
  width: 45px;
  background-color: #EF5693;
}

.language_select {
  pointer-events: auto;
  position: absolute;
  top: 60px;
  right: 8px;
  width: auto;
  height: auto;

}

.language_select_item {
  pointer-events: auto;
  cursor: pointer;
  padding: 8px;
  background-color: #fff;
  border-radius: 15px;
  font-size: 12px;
  margin-bottom: 12px;
  transition: all 200ms linear;
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.2);
}

.language_select_item:hover {
  transform: scale(1.05);
  color: #1A66DE;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .5s;
}

.fade-enter,
.fade-leave-to {
  transform: translateX(100%);
}

.language_change_bg {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 85px;
  height: 100%;
  pointer-events: none;
}
</style>
