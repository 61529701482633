import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import { add } from 'mathjs';
const cn = {
    menu: {
        home: '首页',
        Recharge: {
            title: '充值',
            subMenu: {
                onlineRecharge: '在线充值',
                myWallet: '我的钱包',
                rechargrLogs: '充值记录',
                BalanceStatement: '余额流水',
                withdraw: '余额提现'
            }
        },
        card: {
            title: '卡片',
            subMenu: {
                applyCard: '申请卡片',
                myCard: '我的卡片',
                saleLogs: '交易记录',
                Recordofdishonour: '拒付记录',
            }
        },
        extend: {
            title: '推广',
            subMenu: {
                AlliancePromotion: '联盟推广',
                SplitDiary: '分成日记',
            }
        },
        advertisement: {
            title: '广告',
            subMenu: {
                CorporateAdvertising: '企业广告',
                EnterpriseCertification: '企业认证',
                AdvertisingAlliance: '广告联盟',
            }
        },
        shopping:{
            title: '商城',
            tips:"正在开发中......"
        },
        withdraw: {
            title: '余额提现',
        },
        Toggle: {
            title: '切换',
        },
        setting: {
            title: '设置',
            subMenu: {
                IndividualCenter: '个人中心',
                GoogleBinding: '谷歌绑定',
                ChangePassword: '修改密码',
                logout: '退出登录',
            }
        },
        scanTheCodeOnWeChat: '微信扫一扫'
    },
    userInfos: {
        personalCenter: '个人中心',
        username: '用户名',
        name: '姓名',
        numberOfInvitedRegistrants: '邀请注册人数',
        timeOfRegistration: '注册时间',
        usdBalance: 'USD余额',
        whetherOrNotYouHaveARealName: '是否实名',
        numberOfCardsOpened: '开卡数量',
        loginAddress: '登录地址',
        email:"邮箱",
        cnyBalance: 'CNY余额',
        invitationCode: '邀请码',
        rejectionRate: '拒付率',
        freeze: '冻结',
        realNameNamed: '已实名',
        noRealName: '未实名',
    },
    googleBinding: {
        pre: '请使用',
        content: 'Authenticator',
        after: '{扫一扫}功能，扫描二维码完成谷歌验证码登录绑定。',
    },
    changePassword: {
        title: '修改密码',
        oldPWD: '请输入旧密码',
        newPWD: '请输入新密码',
        rule: '密码只能包含大小字母和数字,{a-z,A-z,0-9}',
        confirm: '确 定',
        cancel: '取 消',
    },
    changeEmail:{
        title:'修改邮箱',
        placeholder:'请输入邮箱地址',
        confirm: '确 定',
        cancel: '取 消',
    },
    welcome: {
        customerServiceWeChat: '客服微信',
        mobile: '移动端',
        announcement: '公告',
        home: '首页',
        main: '主页',
        working: '工作时间 10:00-18:30'
    },
    onlineRecharge: {
        title: '在线充值',
        home: '首页',
        depositApplication: '充值申请',
        depositInUsd: '美金充值',
        depositFee: '充值手续费(%):',
        zfbdepositFeedes: '手续费中包括1%支付宝手续费',
        wxdepositFeedes: '手续费中包括1%微信手续费',
        topUpAmount: '充值金额',
        depositMethod: '充值方式',
        crossBorderTopUp: '跨境充值',
        timelyArrival: '及时到账',
        hongKongForBusiness: '香港对公',
        ustdRecharge: '泰达充值',
        nextDayArrival: '次日到账',
        depositCurrency: '充值币种:',
        usd: '美金',
        theActualAmountReceived: '实际到账金额:',
        notAtThisTime: '暂不支持',
        bankTransfer: '银行转账',
        assetOverview: '资产总览',
        rechargeRecord: '充值记录',
        assetBalances: '资产余额',
        availableBalance: '可用余额',
        availableBalancedes: '可用余额=资产余额-审核中充值金额',
        freezeTheAmount: '冻结金额',
        frequentlyAskedQuestions: '常见问题',
        des1: '交易后仍然显示交易中或待支付怎么办？',
        des2: '交易成功后，需要几秒钟后才能到账，等待几秒钟后仍未更新，可先刷新页面；等待已久仍然未到账，可联系客服或运营经理。',
        weChatPay: '微信支付',
        alipayPayment: '支付宝支付',
        des3: '请于2小时内完成支付',
        close: '关闭',
        submitYourOrder: '提交订单',
    },
    wallet: {
        home: '首页',
        depositApplication: '充值申请',
        renminbi: '人民币',
        title: '钱包',
        availableBalance: '可用余额',
        balanceConversion: '余额转换',
        des1: '汇率实时更新详情请根据中国银行汇率卖出价格进行参考。',
        transferOutOfTheWallet: '转出钱包',
        des2: '请选择活动区域',
        usd: '美金',
        theAmountToBeTransferredOut: '转出金额',
        transferToYourWallet: '转入钱包',
        transferInAmount: '转入金额',
        confirmTheRedemption: '确认兑换',
        purse: '钱包',
    },
    transactionHistory: {
        home: '首页',
        title: '充值记录',
        depositApplication: '充值申请',
        des4: '开始日期',
        des5: '结束日期',
        des6: '请输入订单号',
        des7: '充值渠道',
        des8: '充值状态',
        search: '搜索',
        flushed: '刷新',
        download: '下载',
        redemptionHistory: '兑换记录',
        orderNumber: '订单编号',
        remark: '备注',
        topUpUsers: '充值用户',
        creationTime: '创建时间',
        rechargeChannels: '充值渠道',
        alipay: '支付宝',
        wechat: '微信',
        bankCards: '银行卡',
        manualTopUp: '人工充值',
        manualDeductions: '人工扣除',
        offshoreTopUp: '离岸充值',
        cryptocurrencies: '加密货币',
        amount: '金额',
        theEstimatedAmountToBeReceived: '预计到账金额',
        theTypeOfDepositCurrency: '充值币种',
        walletBalance: '钱包余额',
        topUpStatus: '充值状态',
        waitForPayment: '等待付款',
        success: '成功',
        fail: '失败',
        successpay: '充值成功',
        failpay: '充值失败',
        cancelThePayment: '取消支付',
        theTopUpExpires: '充值过期',
        operation: '操作',
        repay: '重新支付',
        cancelThePayment: '取消支付',
        weChatPay: '微信支付',
        des1: '请于2小时内完成支付.',
        close: '关闭',
        username: '用户名',
        orderId: '订单id',
        redemptionTime: '兑换时间',
        spendingAmountCny: '消费金额(CNY)',
        exchangeRate: '兑换汇率',
        exchangeAmountUsd: '兑换金额(USD)',
        redemptionStatus: '兑换状态',
        theLastWeek: '近一周',
        theLastMonth: '近一月',
        lastThreeMonths: '近三月',
    },
    balanceDetails: {
        home: '首页',
        title: '美金记录',
        depositApplication: '充值申请',
        des1: '请输入卡号',
        startDate: '开始日期',
        endDate: '结束日期',
        des2: '请选择事件类型',
        des3: '请选择支付状态',
        search: '搜索',
        export: '导出',
        flushed: '刷新',
        cardNumber: '卡号',
        theUserToWhomItBelongs: '所属用户',
        operators: '操作人员',
        operatingTime: '操作时间',
        operationalEvents: '操作事件',
        cardTopUp: '卡充值',
        theCardIsTransferredOut: '卡转出',
        signUpFee: '开卡费',
        cancellationCardTransfer: '销卡转入',
        revokeTheTransferIn: '撤销转入',
        insufficientBalanceIsDeducted: '网关失败',
        crossBorderFees: '跨境手续费',
        retroactiveHandlingFee: '补缴手续费',
        refundProcessingFee: '退款手续费',
        cardTransactionFees: '交易手续费',
        advertisingFeePayment: '广告费支付',
        advertisingFeeRefund: '广告费退款',
        paymentStatus: '支付状态',
        success: '成功',
        fail: '失败',
        amount: '金额',
        walletBalance: '钱包余额(USD)',
        usdRecords: '美金记录',
        theLastWeek: '近一周',
        theLastMonth: '近一月',
        lastThreeMonths: '近三月',
    },
    applyCard: {
        home: '首页',
        title: '申请卡片',
        cardManagement: '卡片管理',
        des1: '请输入卡号段',
        cardStatus: '卡状态',
        cardNumberSegment: '卡号段',
        cardDecks: '卡组',
        region: '所属地区',
        cardCurrency: '卡币种',
        customizeYourBillingAddress: '自定义账单地址',
        signUpFunction: '开卡功能',
        rechargeFunction: '充值功能',
        rolloutFunction: '转出功能',
        cancelTheCardFunction: '销卡功能',
        freezeFunction: '冻结功能',
        numberOfCardsOpened: '开卡数量',
        signUpFee: '开卡费',
        des2: '请联系客户经理调整',
        des3: '充值手续费(%)',
        cardDescription: '卡描述',
        operation: '操作',
        yes: '可申请',
        notAvailable: '不可申请',
        des4: '联系客户经理开通权限',
        apply: '申请',
        applyForACard: '申请卡片',
        FcardNumber: '卡编号:',
        FcardNumberSegment: '卡号段:',
        FexpirationDate: '有效期:',
        FcardCurrency: '卡币种:',
        FcardDescription: '卡描述:',
        FsignUpFee: '开卡费:',
        FdepositFee: '充值手续费:',
        FbalanceUsd: '余额(USD):',
        FbalanceCny: '余额(CNY):',
        FnumberOfApplications: '申请数量:',
        FtopUpAmount: '充值金额',
        FtotalSpending: '合计支出',
        Fcancel: '取消',
        Fconfirm: '确定',
        Fdes5: '手续费内扣卡段建议多充值1-2美金,防止付款失败.',
    },
    queryCard: {
        home: '首页',
        title: '查询卡片',
        cardManagement: '卡片管理',
        des1: '请输入卡号',
        creationTime: '创建时间',
        cardStatus: '卡片状态',
        search: '搜索',
        flushed: '刷新',
        bulkRecharge: '批量充值',
        downloadTheCard: '下载卡片',
        normalCard: '正常卡',
        numberOfDownloads: '下载次数',
        operation: '操作',
        cardInformation: '卡片信息',
        cardNumber: '卡号',
        currency: '币种',
        creationTime: '创建时间',
        cardStatus: '卡状态',
        normal: '正常',
        theCardIsLocked: '已锁卡',
        cancelledCard: '已销卡',
        cardNotes: '卡备注',
        des2: '请输入卡备注',
        cardBalance: '卡片余额',
        synchronous: '同步',
        operation: '操作',
        recharge: '充值',
        cancellationOfTheCard: '销卡',
        rollOut: '转出',
        cardDetails: '卡片详情',
        showCardDetails: '复制,显示卡详情',
        month: '月',
        year: '年',
        consumptionHistory: '消费记录',
        flushed: '刷新',
        refundRecordInquiry: '退款记录查询',
        updateTheAddress: '更新地址',
        cardNumber: '卡号',
        theNameOfTheBusiness: '商户名称',
        orderTime: '订单时间',
        theAmountSpent: '消费金额',
        theTypeOfTransaction: '交易类型',
        transactionStatus: '交易状态',
        transactionDescription: '交易描述',
        theCurrencyOfTheTransaction: '交易币种',
        updateYourBillingAddress: '更新账单地址',
        countryCode: '国家码',
        provinceState: '省/州',
        city: '所在城市(市)',
        zip: '邮编',
        fullAddress: '详细地址',
        cancel: '取消',
        confirm: '确定',
        cardTopUp: '卡片充值',
        rechargeCardNumber: '充值卡号',
        cardBalance: '卡片余额',
        cardNumberCurrency: '卡号币种',
        walletBalanceUsd: '钱包余额(USD)',
        cardRechargeFee: '卡充值手续费%',
        topUpAmount: '充值金额',
        estimatedCost: '预计花费',
        rechargeRemarks: '充值备注',
        des3: '手续费内扣卡段建议多充值1-2美金,防止付款失败.',
        theCardNumberIsTransferredOut: '卡号转出',
        transferOutCardNumber: '转出卡号',
        cardNumberBalance: '卡号余额',
        theAmountToBeTransferredOut: '转出金额',
        rollOutTheNote: '转出备注',
        refundHistory: '退款记录',
        cardNumber: '卡号',
        billingCurrency: '账单币种',
        transactionStatus: '交易状态',
        theRefundAuthorizationWasSuccessful: '退款授权成功',
        refundAuthorizationFailed: '退款授权失败',
        refundAuthorization: '退款授权中',
        punchTheRightSuccess: '冲正成功',
        failedToStraighten: '冲正失败',
        billAmount: '账单金额',
        liquidationTime: '清算时间',
        theNameOfTheBusiness: '商户名称',
        remark: '备注',
        bulkRecharge: '批量充值',
        walletBalanceUsd: '钱包余额(USD)',
        numberOfTopUpCards: '充值卡数量',
        cardRechargeFee: '卡充值手续费%',
        topUpAmount: '充值金额',
        estimatedCost: '预计花费',
        rechargeRemarks: '充值备注',
    },
    saleType: {
        consume: '消费',
        correctionOfAuthorization: '纠正授权',
        verify: '验证',
        quash: '撤销',
        refund: '退款',
        validRefunds: '效正退款',
        recharge: '充值',
        cardBalanceRefund: '卡余额退款',
        theCancelledCardIsReturned: '销卡退回',
        serviceCharge: '服务费',
        revocationOfRefunds: '退款撤销',
        authorizedConsumption: '授权消费',
        refund: '退款',
        theCardIsTransferredOut: '卡转出',
        cardTransferIn: '卡转入',
    },
    saleStatus: {
        theTransactionWasSuccessful: '交易成功',
        theTransactionFailed: '交易失败',
        trading: '交易中',
        theTransactionReversalIsSuccessful: '交易撤销成功',
        transactionReversalFailed: '交易撤销失败',
    },
    businessHistory: {
        home: '首页',
        title: '交易记录',
        cardManagement: '卡片管理',
        des1: '请输入卡号',
        des2: '请选择操作事件',
        des3: '请选择交易状态',
        startDate: '开始日期',
        endDate: '结束日期',
        search: '搜索',
        flushed: '刷新',
        download: '下载',
        username: '用户名',
        operationalEvents: '操作事件',
        cardNumber: '卡号',
        theNameOfTheMerchant: '商户名称',
        tradingHours: '交易时间',
        theAmountOfTheTransaction: '交易金额',
        theCurrencyOfTheTransaction: '交易币种',
        transactionStatus: '交易状态',
        transactionDescription: '交易描述',
        theLastWeek: '近一周',
        theLastMonth: '近一月',
        lastThreeMonths: '近三月',
    },
    chargebackHistory: {
        home: '首页',
        title: '拒付记录',
        cardManagement: '卡片管理',
        rejectionRate: '拒付率',
        failedAmount: '失败金额',
        clampHeaders: '卡头',
        theTotalNumberOfTransactions: '总交易数',
        numberOfFailures: '失败次数',
        theNumberOfChargebacks: '拒付笔数',
        theTotalAmountSpent: '总消费金额',
        failedAmount: '失败金额',
        theAmountOfTheChargeback: '拒付金额',
        updated: '更新时间',
    },
    affiliatePromotion: {
        home: '首页',
        title: '客户管理',
        des1: '请输入用户名',
        username: '用户名',
        timeOfRegistration: '注册时间',
        invitationCode: '邀请码',
        userStatus: '用户状态',
        normal: '正常',
        deactivated: '停用',
        toBeReviewed: '待审核',
        notPassed: '不通过',
        operation: '操作',
        rechargeHistory: '充值记录',
        inquireAboutTheCard: '查询卡',
        turnoverRecords: '流水记录',
        des2: '请输入卡号',
        rechargeChannels: '充值渠道',
        startDate: '开始日期',
        endDate: '结束日期',
        search: '搜索',
        brushNew: '刷 新',
        orderNumber: '订单编号',
        theUserToWhomItBelongs: '所属用户',
        operators: '操作人员',
        operatingTime: '操作时间',
        rechargeChannels: '充值渠道',
        alipay: '支付宝',
        wechat: "微信",
        bankCards: '银行卡',
        manualTopUp: '人工充值',
        manualDeductions: '人工扣除',
        offshoreTopUp: '离岸充值',
        cryptocurrencies: '加密货币',
        theTypeOfDepositAmount: '充值金额类型',
        theAmountReceived: '到账金额',
        remark: '备注',
        topUpStatus: '充值状态',
        waitForPayment: '等待付款',
        success: '成功',
        fail: '失败',
        cancelThePayment: '取消支付',
        queryCards: '查询卡片',
        des3: '请输入卡号',
        dateOfApplication: '申请时间',
        cardStatus: '卡片状态',
        cardTransactionHistory: '卡交易记录',
        cardNumber: '卡号',
        theNameOfTheBusiness: '商户名称',
        orderTime: '订单时间',
        theAmountSpent: '消费金额',
        theTypeOfTransaction: '交易类型',
        transactionStatus: '交易状态',
        transactionDescription: '交易描述',
        theCurrencyOfTheTransaction: '交易币种',
        cardType: '卡类型',
        shareCards: '共享卡',
        cardNumber: '卡号',
        currency: '币种',
        signUpDate: '开卡日期',
        cardStatus: '卡状态',
        normal: '正常',
        locked: '锁卡',
        cancellationOfTheCard: '销卡',
        availableBalance: '可用余额',
        cardNotes: '卡备注',
        productBtn:"产品配置",
        priceBtn:"价格配置",
        cardConfig:"卡段配置",
        plantag:"机票",
        addtag:"广告",
        shoptag:"电商",
        apptag:"订阅",
        othertag:"其他",
        comfirmBtn:"确定",
        priceConfig:"价格配置",
        production:"产品",
        isUserSetConfig:"是否以用户设置为准",
        cardFee:"开卡费",
        rechargeFee:"充值手续费",
        changeBtn:"修改",
        tips:"权限不足！"
    },
    partitioninglogs: {
        home: '首页',
        title: '分成日记',
        des1: '充值用户名',
        des2: '请选择支付状态',
        startDate: '开始日期',
        endDate: '结束日期',
        search: '搜索',
        brushNew: '刷新',
        topUpYourUsername: '充值用户名',
        theAmountReceivedAfterTheTopUp: '充值后到账金额',
        itIsDividedIntoCurrencyTypes: '分成货币类型',
        theTypeOfTransaction: '交易类型',
        agentsShared: '代理分成',
        userWithdrawalDeduction: '用户提现扣减',
        splitAmount: '分成金额',
        divideTheTotalBalance: '分成总余额',
        splitExchangeRate: '分成汇率',
        divideIntoTime: '分成时间',
    },
    gg: {
        home: '首页',
        title: '广告投放高额返点',
        des1: '请选择任一媒体开通广告账户，开启出海之旅',
        des11: '全球最大社交平台，月活跃度28.9亿',
        des2: '若您刚接触海外社交媒体营销，推荐您选择Facebook',
        des3: '无论您的客户是谁，都能找到他们',
        corporateAccountOpening: '企业开户',
        des4: '全球最受欢迎的短视频社交平台之一',
        des5: '覆盖150多个国家的超10亿用户',
        des6: '适合目标人群为18-24岁且具有互动偏好性的客户',
        des7: '全球最大的搜索引擎公司，覆盖全球90%互联网用户',
        des8: '熟悉用户购物喜好，可追溯行为轨迹',
        des9: '通过关键词搜索匹配您的客户',
        des10: '微信扫码添加经理开户。',
        cancel: '取消',
    },
    enterpriseCertification: {
        home: '首页',
        title: '企业认证',
        applyForCertification: '申请认证',
        certificationAudits: '认证审核',
        auditResults: '审核结果',
        typeOfBusiness: '企业类型',
        des1: '请选择企业类型',
        hongKongEnterprises: '香港企业',
        chineseEnterprises: '中国企业',
        theNameOfTheBusiness: '企业名',
        certificateOfIncorporation: '企业注册证书',
        des2: '仅限一张照片: PNG,JPG,JPEG: 最大10MB',
        reviewStatus: '审核状态',
        passed: '已通过',
        reject: '驳回',
        application: '申请',
        recertification: '重新认证',
        des3: '认证审核中，我们可能需要最快1-3天内审核完您的资料.请耐心等待......',
        des4: '请选择企业名称',
        hongKongEnterprises: '香港企业',
        chineseEnterprises: '中国企业',
        uSBusinesses: '美国企业',
        btn:"点击上传",
        tips:"只能上传PDF文件(要求上传登记证书、注册证书、法团成立表格)"
    },
    eCommerceCustomerAcquisition: {
        home: '首页',
        title: '电商客户',
        des1: '请输入广告口令-识别号',
        search: '搜索',
        brushNew: '刷新',
        applyForAnAdvertisement: '申请广告',
        reviewStatus: '审核状态',
        approved: '审核通过',
        underReview: '审核中',
        reviewRejection: "审核驳回",
        taskStatus: "任务状态",
        didnTStart: '未开始',
        screenshotOfTheTask: '任务截图',
        pause: '暂停',
        comeToAnEnd: '结束',
        passwordIdentificationNumber: '口令-识别号',
        deliveryMode: '投放模式',
        flowRate: '流量',
        sales: '销量',
        theTypeOfImpression: '展示类型',
        site: '网站',
        informationApplications: '信息应用',
        targetStrategy: '目标策略',
        maximizeConversions: '转化量最大化',
        maximizeConversionValue: '转化价值最大化',
        startDate: '开始日期',
        startTime: '开始时间',
        endDate: '结束日期',
        endTime: '结束时间',
        deliveryBudget: '投放预算',
        numberOfDaysForDuration: '持续天数',
        totalBudget: '总预算',
        totalCost: '总花费',
        country: '投放国家',
        launchCity: '投放城市',
        ageOfTheTargetGroup: '目标人群年龄',
        genderOfTheTargetGroup: '目标人群性别',
        language: '语言',
        adHeadline: '广告标题',
        descriptionOfTheAd: '广告描述',
        advertisingImages: '广告图片',
        theUrlOfTheAdvertisingWebsite: '广告网站URL',
        whatsAppContacts: 'WhatsApp联系人',
        creationTime: '创建时间',
        updated: '更新时间',
        operation: '操作',
        des2: '审核驳回才允许编辑',
        editorial: '编辑',
        des3: '将您的品牌',
        des4: '推向更广阔的市场！',
        des5: '投放模式',
        des6: '吸引更多的用户流量到您的网站或应用，增加品牌知名度和曝光度。',
        des7: '促进产品销售，提高转化率和订单量，以实现更直接的商业目标。',
        theTypeOfImpression: '展示类型',
        websiteDelivery: '网站投放',
        appInformation: '应用信息',
        targetStrategy: '目标策略',
        pleaseSelectATargetPolicy: '请选择目标策略',
        des8: '广告带来的转化次数最多，例如点击购买、提交表单或其他你定义的转化行动。这意味着更多的用户在广告的引导下执行了预期的行动，提高了广告的效果。',
        maximizeConversions: '转化量最大化',
        des9: '不同于单纯的转化次数，转换价值考虑了每次转化的质量和价值。例如，用户购买高价商品所带来的价值高于购买低价商品。这种情况下，广告优化的重点是提高每次转化的质量和价值。',
        maximizeConversionValue: '转化价值最大化',
        budgetForAdServing: '广告投放预算',
        advertisingBudget: '广告预算',
        premium: '手续费',
        totalBudget: '总预算',
        balance: '余额',
        theNumberOfDaysTheAdHasBeenRunning: '广告投放天数',
        des10: '请输入投放天数',
        startTime: '开始时间',
        des11: '请选择开始时间',
        des12: '选择开始时间',
        endTime: '结束时间',
        endDate: '结束日期',
        region: '投放地区',
        des13: '请使用英文输入国家',
        des14: '请输入投放国家',
        des15: '请使用英文输入城市',
        des16: '请输入投放城市',
        genderOfTheTargetGroup: '目标群体性别',
        des17: '请选择目标群体',
        random: '随机',
        man: '男人',
        woman: '女人',
        des18: '目标群体年龄',
        des19: '目标群体年龄18-35岁',
        deliveryLanguage: '投放语言',
        des20: '请输入语言',
        adHeadline: '广告标题',
        des12: '请输入广告标题',
        descriptionOfTheAd: '广告描述',
        des15: '请输入广告描述',
        serveYourWebsite: '投放网站',
        des13: '请输入投放网站',
        contact: '联系方式',
        des14: '请输入您的whatsapp联系方式',
        previous: '上一步',
        next: '下一步',
        apply: '申请',
        cancel: '取消',
        eCommerceGoesOverseas: '海外电商',
    },
    balanceWithdrawals: {
        home: '首页',
        title: '余额提现',
        walletBalance: '钱包余额',
        payouts: '提现',
        withdrawalsAreFrozen: '提现冻结',
        withdrawalExchangeRate: '提现汇率',
        orderNumber: '订单号',
        withdrawalUsername: '提现用户名',
        theAmountToBeWithdrawn: '提现金额',
        withdrawalCurrency: '提现币种',
        payoutStatus: '提现状态',
        underReview: '审核中',
        approved: '审核通过',
        reviewRejection: '审核驳回',
        alipayAccount: '支付宝账号',
        alipayName: '支付宝姓名',
        withdrawalRequestRemarks: '提现申请备注',
        approvalNotes: '审核备注',
        underReview: '审核中',
        reviewTime: '审核时间',
        withdrawalTime: '提现时间',
        withdrawalExchangeRate: '提现汇率',
        payoutStatus: '提现状态',
        theCurrencyOfTheTransaction: '交易币种',
        search: '搜索',
        flushed: '刷新',
        withdrawalRequests: '提现申请',
        withdrawalsAreFrozen: '提现冻结',
        withdrawalExchangeRate: '提现汇率',
        orderNumber: '订单号',
        withdrawalUsername: '提现用户名',
        theAmountToBeWithdrawn: '提现金额',
        withdrawalCurrency: '提现币种',
        payoutStatus: '提现状态',
        underReview: '审核中',
        approved: '审核通过',
        reviewRejection: '审核驳回',
        alipayAccount: '支付宝账号',
        alipayName: '支付宝姓名',
        withdrawalRequestRemarks: '提现申请备注',
        approvalNotes: '审核备注',
        reviewTime: '审核时间',
        withdrawalTime: '提现时间',
        withdrawalExchangeRate: '提现汇率',
        withdrawalRequests: '提现申请',
        dollar: '美元',
        balance: '余额',
        theAmountReceived: '到账金额',
        submitAnApplication: '提交申请',
        theLastWeek: '近一周',
        theLastMonth: '近一月',
        lastThreeMonths: '近三月',
    },
    login: {
        logInToYourInstantAccount: '登录您的即时账户',
        signUpForAnAccount: '没有账户?注册账号',
        ForgotPassword:'忘记密码',
        des1: '请输入用户名',
        des2: '请输入密码',
        des3: '请输入验证码',
        des4: '请输入谷歌验证码',
        login: '登录',
        otherWays: '其他方式',
        googleLogin: '谷歌登录',
        accountLogin: '账号登录',
        coderule: '验证码不能为空',
        userrule: '用户名不能为空',
        pwdrule: '密码不能为空',
        lengthrule: '长度在 5 到 12 个字符',
        abcrule: '密码只能包含大小字母和数字,{a-z,A-z,0-9}',
        ggrule: '验证码长度为6',
    },
    forgot:{
        title:"找回密码",
        backLogin:"返回登录",
        signUpForAnAccount: '没有账户?注册账号',
        des1: '请输入用户名',
        des2:'请输入邮箱',
        des3: '请输入验证码',
        des4: '请输入新密码',
        des5: '请再次输入新密码',
        submit:'修改密码',
        coderule: '验证码不能为空',
        userrule: '用户名不能为空',
        pwdrule: '密码不能为空',
        lengthrule: '长度在 5 到 12 个字符',
        abcrule: '密码只能包含大小字母和数字,{a-z,A-z,0-9}',
        emailrule: '请输入邮箱地址',
        sendBtn:"发送邮箱",
    },
    register: {
        signUpForYourInstantAccount: '注册您的即时账户',
        country: '国家',
        des1: '请输入手机号码',
        des2: '请输入密码',
        des3: '请再次输入密码',
        des4: '请输入邀请码',
        des5: '请输入姓名',
        des6: '请输入身份证号码',
        des7: '请输入验证码',
        des8: '两次密码输入不一致',
        des9: '请输入邮箱',
        enroll: '注册',
        readAndAgree: '阅读并同意',
        registrationAgreement: '注册协议',
        privacyAgreement: '隐私协议',
        refundAgreement: '保密协议',
        logInNow: '已有账户?立即登录',
        userrule: '用户名不能为空',
        userLengthrule: '长度在 11 个字符',
        pswrule: '密码不能为空',
        pswLengthrule: '长度在 5 到 15 个字符',
        pswabcrule: '密码只能包含大小字母和数字,{a-z,A-z,0-9}',
        pswAgainrule: '两次密码输入不一致',
        codeurule: '邀请码必须填写',
        namerule: '姓名必须填写',
        chineserule:'必须填写中文',
        idrule: '身份证号必须填写'
    },
    globalpage: {
        footer: ' '
    },
    ...zhLocale
}
// 导出
export default cn;
