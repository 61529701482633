<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{
          $t("enterpriseCertification.home")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          $t("enterpriseCertification.title")
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <!-- 搜索框 -->
          <div class="deve_box">
            <!-- fb -->
            <!-- <div class="deve_img_box">
                    <img src="@/images/svg/deve.svg" width="50%" />
                    
                  </div>
                  <div class="deve_title">
                    页面正在开发中......
                  </div> -->
            <!-- 步骤条 -->
            <div class="step_box">
              <el-steps :active="activePage" simple>
                <el-step :title="$t('enterpriseCertification.applyForCertification')" icon="el-icon-edit"></el-step>
                <el-step :title="$t('enterpriseCertification.certificationAudits')" icon="el-icon-upload"></el-step>
                <el-step :title="$t('enterpriseCertification.auditResults')" icon="el-icon-picture"></el-step>
              </el-steps>
            </div>
            <div class="tabs_box">
              <!-- 申请认证 -->
              <div v-if="activePage != 1" class="applyAuth_box">
                <el-form :rules="rules" :model="formLabelAlign" labelWidth="200px" ref="ruleForm"
                  :disabled="activePage == 3">
                  <el-form-item :label="$t('enterpriseCertification.typeOfBusiness')" prop="type">
                    <el-select style="width: 400px" v-model="formLabelAlign.type"
                      :placeholder="$t('enterpriseCertification.des1')" @change="imageChange">
                      <el-option :label="$t('enterpriseCertification.hongKongEnterprises')
                        " value="香港企业">
                      </el-option>
                      <!-- <el-option label="美国企业" value="美国企业"> </el-option> -->
                      <el-option :label="$t('enterpriseCertification.chineseEnterprises')
                        " value="中国企业">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="$t('enterpriseCertification.theNameOfTheBusiness')" prop="name">
                    <el-input style="width: 400px" v-model="formLabelAlign.name"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('enterpriseCertification.certificateOfIncorporation')
                    " prop="fileList" v-show="imageText">
                    <div class="upload_box">
                      <el-upload v-if="imageText == '营业执照'" action="#" list-type="picture-card"
                        :http-request="handleUploadHK" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                        accept="image/jpeg, image/png, image/jpg" :fileList="fileList" :limit="1">
                        <i class="el-icon-plus"></i>

                        <div class="el-upload__tip" slot="tip">
                          &bull; &nbsp;
                          {{ $t("enterpriseCertification.des2") }}
                        </div>
                      </el-upload>
                      <el-upload v-if="imageText == '登记书'" action="#" :on-remove="handleRemoveHk"
                        :http-request="handleUploadHK" accept="application/pdf" multiple :limit="3"
                        :before-upload="uploadFileHK" :file-list="fileList">
                        <el-button size="small" type="primary">{{ $t("enterpriseCertification.btn") }}</el-button>
                        <div slot="tip" class="el-upload__tip">
                          {{ $t("enterpriseCertification.tips") }}
                        </div>
                      </el-upload>
                      <div class="test_image_box" v-if="imageText == '营业执照'">
                        <div class="test_image_text">{{ imageText }}</div>
                        <el-image class="test_image" :src="testImageUrl" :preview-src-list="[testImageUrl]">
                        </el-image>
                      </div>
                    </div>

                    <el-dialog top="3vh" :visible.sync="dialogVisible">
                      <img width="100%" :src="formLabelAlign.fileList" alt="" />
                    </el-dialog>
                  </el-form-item>
                  <el-form-item :label="$t('enterpriseCertification.reviewStatus')" v-if="activePage == 3">
                    <div class="applyData_box success" v-if="formLabelAlign.status == 2">
                      {{ $t("enterpriseCertification.passed")
                      }}<svg t="1709376859608" class="icon_status" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="5068" width="128" height="128">
                        <path
                          d="M512 1024a512 512 0 1 1 0-1024 512 512 0 0 1 0 1024z m-71.318588-361.411765a29.334588 29.334588 0 0 0 20.48-8.252235L774.625882 349.364706a27.708235 27.708235 0 0 0 0-39.936 29.575529 29.575529 0 0 0-41.08047 0l-292.74353 284.912941L290.454588 448.150588a29.575529 29.575529 0 0 0-41.08047 0 27.708235 27.708235 0 0 0 0 39.996236l170.706823 166.128941a29.274353 29.274353 0 0 0 20.540235 8.252235z"
                          fill="#33A954" p-id="5069"></path>
                      </svg>
                    </div>
                    <div class="applyData_box fail" v-if="formLabelAlign.status == 3">
                      {{ $t("enterpriseCertification.reject") }}
                      <el-tooltip class="item" effect="dark" :content="formLabelAlign.refuse_msg" placement="right">
                        <svg t="1709377378190" class="icon_status" viewBox="0 0 1024 1024" version="1.1"
                          xmlns="http://www.w3.org/2000/svg" p-id="8969" width="128" height="128">
                          <path
                            d="M511.992517 1024A511.992517 511.992517 0 1 0 0 512.129154a512.357531 512.357531 0 0 0 511.992517 511.870846z"
                            fill="#4D4D4D" p-id="8970"></path>
                          <path
                            d="M511.992517 730.16399a40.759861 40.759861 0 0 0-40.75986 40.759861v23.604218a40.759861 40.759861 0 0 0 81.39805 0v-23.239205A40.759861 40.759861 0 0 0 511.992517 730.16399zM323.402118 482.076361a40.759861 40.759861 0 0 0 40.75986-40.638189v-32.607889a134.690047 134.690047 0 0 1 51.101915-102.933856 154.279114 154.279114 0 0 1 127.754787-33.094574c55.968764 10.828739 103.785555 56.212106 113.762596 107.800706a137.97517 137.97517 0 0 1-61.687312 147.343854C529.14816 567.611233 474.51778 605.207642 474.51778 663.60983a40.759861 40.759861 0 0 0 81.39805 0c0-11.437095 27.254355-33.581258 81.033036-65.945804A219.008206 219.008206 0 0 0 736.719271 365.150314c-16.425615-84.196488-89.671693-155.009141-178.126674-172.164785a235.555493 235.555493 0 0 0-194.673961 50.250216 215.723083 215.723083 0 0 0-80.789694 165.71621v32.607888a40.759861 40.759861 0 0 0 40.273176 40.516518z"
                            fill="#FFFFFF" p-id="8971"></path>
                        </svg>
                      </el-tooltip>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <el-button v-if="activePage == 0" class="setBillBtn" @click="apply" :loading="applyLoading">
                      {{ $t("enterpriseCertification.application") }}
                    </el-button>
                  </el-form-item>
                </el-form>
                <div class="reset_group">
                  <el-button v-if="activePage == 3 && formLabelAlign.status == 3" class="setBillBtn" @click="rest()">
                    {{ $t("enterpriseCertification.recertification") }}
                  </el-button>
                </div>
              </div>
              <!-- 审核中 -->
              <div v-else class="antuStaus_box">
                <img src="@/images/svg/examine.svg" alt="" srcset="" />
                <div class="deve_title">
                  {{ $t("enterpriseCertification.des3") }}
                  <!-- 认证审核中，我们可能需要最快1-3天内审核完您的资料.请耐心等待...... -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import { put, signatureUrl, getFileNameUUID } from "@/utils/alioss.js";
export default {
  data() {
    let validateFileUrl = (rule, value, callback) => {
      if (this.fileList.length < 1) {
        //我控制了FileList 长度代表文件个数
        callback(new Error("请上传文件"));
      } else {
        callback();
      }
    };

    return {
      name: "企业认证",
      activePage: 0,
      dialogVisible: false,
      formLabelAlign: {},
      rules: {
        type: [
          {
            required: true,
            message: this.$t("enterpriseCertification.des4"),
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: this.$t("enterpriseCertification.des4"),
            trigger: "blur",
          },
        ],
        fileList: [
          { required: true, validator: validateFileUrl, trigger: "change" },
        ],
      },
      dialogVisible: false,
      dialogImageUrl: "",
      imageUrl: "",
      fileList: [],
      applyLoading: false,
      testImageList: [
        {
          name: '香港企业',
          url: require("@/images/xgimage.png"),
        },
        {
          name: '美国企业',
          url: require("@/images/mgImage.png"),
        },
        {
          name: '中国企业',
          url: require("@/images/zgImage.png"),
        },
      ],
      testImageUrl: "",
      imageText: "",
    };
  },
  created() {
    this.getApplyInfo();
  },
  methods: {
    // 选择图片
    imageChange(value) {
      console.log('value: ', value);
      if (value) {
        if (value == '香港企业') {
          this.imageText = "登记书";
        } else if (value == '美国企业') {
          this.imageText = "注册书";
        } else if (
          value == '中国企业'
        ) {
          this.imageText = "营业执照";
        }
        const img = this.testImageList.filter((item) => item.name === value);
        this.testImageUrl = img[0].url;
        this.fileList = [];
      }
    },
    // 重新认证
    rest() {
      this.activePage = 0;
      this.formLabelAlign = {};
      this.imageText = "";
      this.testImageUrl = "";
      this.fileList = [];
    },
    validateFileUrl() { },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
    },
    handleRemove() {
      if (this.activePage == 3) {
        return;
      }
      this.fileList = [];
      this.formLabelAlign.fileList = "";
    },
    // beforeAvatarUpload(file, files) {
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     // this.dialogImageUrl = e.target.result;
    //     this.formLabelAlign.fileList = e.target.result;
    //   };
    //   reader.readAsDataURL(file);
    // },
    handleChange(file, files) {
      file.status = "success";
      this.fileList = [file];
    },
    getFileName(name) {
      return name.substring(name.lastIndexOf(".")); //.txt
    },
    uploadFileHK(file) {
      console.log("this.fileList.length: ", this.fileList.length);
      if (this.fileList.length == 3) {
        this.$message({
          type: "warning",
          message: "最多只能上传3个文件",
        });
        return;
      }
      return file;
    },
    handleUploadHK(option) {
      var obj = option.file.name;
      var index = obj.lastIndexOf(".");
      obj = obj.substring(index, obj.length);
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = getFileNameUUID() + this.getFileName(option.file.name);
      // 获取当前时间戳
      const timestamp = new Date().getTime();

      // 在文件名中添加时间戳
      const fileNameWithTimestamp = `${timestamp}_${option.file.name}`;
      put("/ENIamge/" + fileNameWithTimestamp, option.file).then((res) => {
        // 上传成功之后，转换真实的地址
        signatureUrl("/ENIamge/" + fileNameWithTimestamp).then((resSign) => {
          this.fileList.push({
            name: option.file.name,
            url: resSign.slice(0, resSign.indexOf("?")),
            status: "success",
          });
          console.log("this.fileList: ", this.fileList);
        });
      });
    },
    handleRemoveHk(file, fileList) {
      this.fileList = fileList;
    },
    // 提交申请
    apply() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // 申请认证
          this.applyLoading = true;
          let params = {
            company_type: this.formLabelAlign.type,
            company_name: this.formLabelAlign.name,
            image: JSON.stringify(this.fileList),
          };
          // return;
          params = this.$lmjm(params);
          const result = this.$http.post("/api/v1/applyCompany", params, {
            headers: {
              token: window.sessionStorage.getItem("token"),
            },
          });
          result
            .then((res) => {
              this.applyLoading = false;
              this.getApplyInfo();
            })
            .catch((err) => {
              this.applyLoading = false;
              console.log(
                "%c [ err ]-171",
                "font-size:13px; background:pink; color:#bf2c9f;",
                err
              );
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 查看申请详细
    getApplyInfo() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getMyCompanyData", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result
        .then((res) => {
          console.log(
            "%c [ res ]-169",
            "font-size:13px; background:pink; color:#bf2c9f;",
            res
          );
          if (res.data.code == 200) {
            if (res.data.data.data) {
              if (res.data.data.data.status == 1) {
                this.activePage = 1;
              } else if (
                res.data.data.data.status == 2 ||
                res.data.data.data.status == 3
              ) {
                this.activePage = 3;
              }
              const detail = res.data.data.data;
              this.formLabelAlign = {
                type: detail.company_type,
                name: detail.company_name,
                status: detail.status,
                refuse_msg: detail.refuse_msg,
              };
              this.fileList = JSON.parse(detail.image);
            } else {
              this.activePage = 0;
              this.formLabelAlign = {};
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(
            "%c [ err ]-171",
            "font-size:13px; background:pink; color:#bf2c9f;",
            err
          );
        });
    },
  },
};
</script>

<style scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/* .setBillBtn {
  height: 40px;
  font-size: 16px;
  font-family: "pingfang";
  border-radius: 4px;
  background-color: #cb0c9f;
  color: #ffffff;
  display: flex;
  justify-content:center ;
  align-items: center;
}
.setBillBtn:hover {
 background-color: #cb0c9f;
 color: #ffffff;
}
.setBillBtn:active {
 background-color: #cb0c9f;
 color: #ffffff;
} */
.reset_group {
  width: 400px;
  margin-left: 120px;
}

.deve_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}

.deve_content {
  font-size: 0.5em;
  margin-left: 18%;
  margin-top: 5%;
}

.tabs_box {
  margin-top: 12px;
  width: 100%;
  height: auto;
  display: flex;
}

.upload_box {
  display: flex;
}

.test_image_box {
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1.5px;
  transition: all 0.5s;
  margin-left: 10px;
}

.test_image_box:hover {
  font-size: 17px;
  letter-spacing: 1.5px;
}

.test_image_text {
  position: absolute;
  top: 74px;
  left: 74px;
  transform: translate(-50%, -50%);

  z-index: 2;
}

.test_image {
  z-index: 1;
  width: 148px;
  height: 148px;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
}

.antuStaus_box {
  margin-top: 12px;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 8px;
}

.antuStaus_box img {
  width: 50%;
  margin-bottom: 60px;
}

.deve_title {
  /* margin-left: 18%; */
  margin-top: 12px;
}

.applyAuth_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
}

.step_box {
  width: 100%;
  height: 60px;
}

.icon_status {
  margin-left: 8px;
  width: 18px;
  height: 18px;
}

.applyData_box {
  display: flex;
  align-items: center;
}

.fail {
  color: red;
}

.success {
  color: green;
}
</style>
