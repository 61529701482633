<template>
  <el-main>
    <div class="title_tab">{{ name }}
      <div style="float: right;line-height: 35px;">
        <!-- <div style="color: #EFB43C;text-align: center;font-weight: 500;display: initial;margin-right: 40px;">
          <span style="font-size: 1.2em;">GG共享: </span>
          <span style="font-size: 1em;">{{ gshareusdmoney }}</span>
        </div> -->
        <div style="color: #EFB43C;text-align: center;font-weight: 500;display: initial;margin-right: 40px;">
          <span style="font-size: 1.2em;">PP共享: </span>
          <span style="font-size: 1em;">{{ pshareusdmoney }}</span>
        </div>
        <div style="color: #EFB43C;text-align: center;font-weight: 500;display: initial;margin-right: 40px;">
          <span style="font-size: 1.2em;">CNY总额: </span>
          <span style="font-size: 1em;">{{ cny }}</span>
        </div>
        <div style="color: #EFB43C;text-align: center;font-weight: 500;display: initial;">
          <span style="font-size: 1.2em;">USD总额: </span>
          <span style="font-size: 1em;">{{ usd }}</span>
        </div>
      </div>
    </div>

    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <!-- 订单编号 -->
            <el-input v-model="searchKeys" placeholder="请输入订单编号" style="width: 15%;margin-right: 30px;"></el-input>
            <!-- 用户名 -->
            <el-input v-model="userNames" placeholder="请输入用户名" style="width: 15%;margin-right: 30px;"></el-input>
            <!-- 充值渠道 -->
            <el-select v-model="czVal" placeholder="充值渠道" clearable style="width: 8%;margin-right: 30px;">
              <el-option v-for="item in selectCz" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <!-- 充值金额 -->
            <el-select v-model="JeVal" placeholder="支付状态" clearable style="width: 8%;margin-right: 30px;">
              <el-option v-for="item in selectJeType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <!-- 日期选择 -->
            <el-date-picker v-model="recordDataTimes" type="daterange" align="right" unlink-panels range-separator="-"
              start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions2" format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-button type="primary" style="margin-bottom: 20px;margin-left: 20px;" icon="el-icon-search"
              @click="searchUserRechargedata()">查 询</el-button>
            <el-button type="primary" style="margin-bottom: 20px;" icon="el-icon-download" @click="batchExports()">下载
            </el-button>
            <el-button type="primary" v-no-more-click icon="el-icon-refresh" @click="getAllUserRechargedata('rf')">刷 新
            </el-button>
            <el-table @selection-change="handleSelectionChange" :data="tableData" style="width: 100%" border
              height="670" max-height="800" size="small" highlight-current-row>
              <el-table-column type="selection" width="55">
              </el-table-column>
              <!-- id- ids，用户名-name，手机号码-tel，上级名称-superior，用户状态-status，注册时间-date，余额-balance，拒付率-dishonor，操作 -->
              <el-table-column label="订单编号" prop="order_num" width="220">
              </el-table-column>
              <el-table-column label="所属用户" prop="users">
              </el-table-column>
              <el-table-column label="代理归属" prop="pname">
              </el-table-column>
              <!-- <el-table-column label="操作人员" prop="playUser">
              </el-table-column> -->
              <el-table-column label="创建时间" prop="createtime" sortable>
              </el-table-column>
              <!-- 充值渠道 -->
              <el-table-column prop="types" label="充值渠道" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.types === 1">
                    支付宝
                  </el-tag>
                  <el-tag v-if="scope.row.types === 2">
                    微信
                  </el-tag>
                  <el-tag v-if="scope.row.types === 3">
                    银行卡
                  </el-tag>
                  <el-tag v-if="scope.row.types === 4" type="warning">
                    人工充值
                  </el-tag>
                  <el-tag v-if="scope.row.types === 5" type="danger"> 人工扣除 </el-tag>
                  <el-tag v-if="scope.row.types === 6">
                    离岸充值
                  </el-tag>
                  <el-tag v-if="scope.row.types === 7" type="danger">
                    加密货币
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column label="充值金额" prop="money">
              </el-table-column>
              <el-table-column label="兑换汇率" prop="usdlv">
              </el-table-column>
              <el-table-column label="到账金额" prop="receipt">
              </el-table-column>
              <!-- 充值金额类型 -->
              <el-table-column prop="montype" label="充值金额类型" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.montype === 1">
                    CNY
                  </el-tag>
                  <el-tag v-if="scope.row.montype === 2" type="danger">
                    USD
                  </el-tag>
                  </el-tag>
                </template>
              </el-table-column>
              <!-- 充值状态 -->
              <el-table-column prop="status" label="充值状态" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 1" type="warning">
                    等待付款
                  </el-tag>
                  <el-tag v-if="scope.row.status === 2" type="success">
                    成功
                  </el-tag>
                  <el-tag v-if="scope.row.status === 3" type="danger">
                    失败
                  </el-tag>
                  <el-tag v-if="scope.row.status === 4" type="info">
                    取消支付
                  </el-tag>
                  <el-tag v-if="scope.row.status === 5" type="danger">
                    充值过期
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column label="RMB余额" prop="rmbyue">
              </el-table-column>
              <el-table-column label="USD余额" prop="usdyue">
              </el-table-column>
              <el-table-column label="CNY总余额" prop="cnycount">
              </el-table-column>
              <el-table-column label="USD总余额" prop="usdcount">
              </el-table-column>
              <!-- 操作 -->
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="danger" size="mini" v-if="scope.row.status === 1" @click="closeOrder(scope.row)">取消支付
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[50, 100, 150, 200]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="rechageTotal">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import {
  dataConversionUtil
} from '../../plugins/excel.js'
export default {
  data() {
    return {
      gshareusdmoney: '',
      pshareusdmoney: '',
      cny: '',
      usd: '',
      czVal: '',
      multipleSelection: [],
      rechageTotal: 0,
      userNames: '',
      JeVal: '',
      selectJeType: [{
        value: 1,
        label: '等待付款'
      },
      {
        value: 2,
        label: '成功支付'
      }, {
        value: 4,
        label: '取消支付'
      }, {
        value: 5,
        label: '充值过期'
      }
      ],
      czNums: '',
      selectCz: [{
        value: 1,
        label: '支付宝'
      }, {
        value: 2,
        label: '微信'
      }, {
        value: 3,
        label: '银行卡'
      }, {
        value: 4,
        label: '人工充值'
      }, {
        value: 5,
        label: '人工扣除'
      }, {
        value: 6,
        label: '离岸充值'
      }, {
        value: 7,
        label: '加密货币'
      }],
      recordDataTimes: [],
      pickerOptions2: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      searchKeys: '', // 财务流水搜索key
      name: '财务流水',
      currentPage: 1, // 当前页码
      pageSize: 50, // 每页数据显示 条数
      labelPosition: 'right',
      tableData: [],
      config_form: {
        online_rachage: '2'
      }
    }
  },
  methods: {
    getCommonNum() {
      let params = {}
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/getCommonNum', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        this.cny = res.data.data.cnycount
        this.usd = res.data.data.usdcount
        this.pshareusdmoney = res.data.data.pshareusdmoney
        this.gshareusdmoney = res.data.data.gshareusdmoney
      }, (error) => {
        console.log(error)
      })
    },
    // 格式化时间
    getTime( /** timestamp=0 **/) {
      const ts = arguments[0] || 0
      let t, y, m, d, h, i, s
      t = ts ? new Date(ts * 1000) : new Date()
      y = t.getFullYear()
      m = t.getMonth() + 1
      d = t.getDate()
      h = t.getHours()
      i = t.getMinutes()
      s = t.getSeconds()
      // 可依据须要在这里定义时间格式
      return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10

        ?
        '0' + d :
        d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (i < 10 ? '0' + i : i) + ':' + (s < 10 ? '0' + s : s)
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getAllUserRechargedata()
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getAllUserRechargedata()
    },
    // 获取财务流水
    getAllUserRechargedata(lf) {
      if (lf == 'rf') {
        this.czVal = ''
        this.userNames = ''
        this.searchKeys = ''
        this.JeVal = ''
        this.recordDataTimes = []
      }
      if (this.recordDataTimes[0] == undefined && this.recordDataTimes[1] == undefined) {
        this.recordDataTimes[0] = ""
        this.recordDataTimes[1] = ""
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        order_num: this.searchKeys,
        username: this.userNames,
        types: this.czVal,
        status: this.JeVal,
        start: this.recordDataTimes[0],
        ends: this.recordDataTimes[1]
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/getAllUserRechargedata', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        this.rechageTotal = res.data.data.total
        this.tableData = res.data.data.data
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].createtime = this.getTime(res.data.data.data[i].createtime)
        }
      }, (error) => {
        console.log(error)
      })
    },
    // 取消支付
    closeOrder(row) {
      let params = {
        id: row.id,
        user_id: row.user_id
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/CancelUserp', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message.success('取消订单成功!')
          this.getAllUserRechargedata()
        } else {
          this.$message.error('操作失败，请重试!')
        }
      }, (error) => {
        console.log('错误:' + error)
      })
    },
    // 条件查询财务流水
    searchUserRechargedata() {
      if (this.recordDataTimes[0] == undefined && this.recordDataTimes[1] == undefined) {
        this.recordDataTimes[0] = ""
        this.recordDataTimes[1] = ""
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        order_num: this.searchKeys,
        username: this.userNames,
        types: this.czVal,
        status: this.JeVal,
        start: this.recordDataTimes[0],
        ends: this.recordDataTimes[1]
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/getAllUserRechargedata', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        this.rechageTotal = res.data.data.total
        this.tableData = res.data.data.data
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].createtime = this.getTime(res.data.data.data[i].createtime)
        }
      }, (error) => {
        console.log(error)
      })
    },
    batchExports() {
      const tableHeader = [
        ['订单编号', '所属用户', '创建时间', '充值渠道', '充值金额', '到账金额', '充值金额类型', '充值状态']
      ]
      const dataList = []
      // 将导出数据存放在this.mumultipleSelection中 ,遍历将要导出的数据
      this.multipleSelection.forEach((item, index) => {
        // 充值状态
        let sta = ""
        switch (item.status) {
          case 1:
            sta = "等待付款"
            break
          case 2:
            sta = "成功"
            break
          case 3:
            sta = "失败"
            break
          case 4:
            sta = "取消支付"
            break
          case 5:
            sta = "充值过期"
            break
        }
        // 充值渠道
        let ty = ""
        switch (item.types) {
          case 1:
            ty = "支付宝"
            break
          case 2:
            ty = "微信"
            break
          case 3:
            ty = "银行卡"
            break
          case 4:
            ty = "人工充值"
            break
          case 5:
            ty = "人工扣除"
            break
          case 6:
            ty = "离岸充值"
            break
          case 7:
            ty = "加密货币"
            break
        }
        dataList.push([
          item.order_num,
          item.users,
          item.createtime,
          ty,
          item.money,
          item.receipt,
          item.montype == '1' ? "CNY" : "USD",
          sta
        ])
      })
      dataConversionUtil.dataToExcel('导出列表名', tableHeader, dataList)
      this.$message.success('导出成功!')
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  },
  created() {
    this.getAllUserRechargedata()
    this.getCommonNum()
  },
  mounted() {

  }
}
</script>

<style scoped></style>
