<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <el-table :data="disData" border style="width: 100%">
              <el-table-column prop="nums" label="卡头">
                <template slot-scope="scope">
                  <el-tag effect="dark">{{ scope.row.nums }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="lv" label="拒付率" sortable :sort-method="(a,b)=>{return a.lv - b.lv}">
                <template slot-scope="scope">
                  <el-tag type="danger" effect="dark">{{ scope.row.lv }}%</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="allnum" label="总交易笔数">
              </el-table-column>
              <el-table-column prop="errnum" label="失败笔数">
              </el-table-column>
              <el-table-column prop="renums" label="拒付笔数">
              </el-table-column>
              <el-table-column prop="allmoney" label="总消费金额">
              </el-table-column>
              <el-table-column prop="renmoney" label="失败金额">
              </el-table-column>
              <el-table-column prop="errmoney" label="拒付金额">
              </el-table-column>
              <el-table-column prop="updatetime" label="更新时间"> </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
  export default {
    data() {
      return {
        name: '拒付记录',
        disData: [],
        currentPage: 1,
        pageSize: 10,
        Total: 0
      }
    },
    methods: {
      // 格式化时间
      getTime( /** timestamp=0 **/ ) {
        const ts = arguments[0] || 0
        let t, y, m, d, h, i, s
        t = ts ? new Date(ts * 1000) : new Date()
        y = t.getFullYear()
        m = t.getMonth() + 1
        d = t.getDate()
        h = t.getHours()
        i = t.getMinutes()
        s = t.getSeconds()
        return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (
          i < 10 ? '0' + i : i) + ':' + (s < 10 ? '0' + s : s)
      },
      getFuseData() {
        let params = {}
        params = this.$lmjm(params)
        const result = this.$http.post('/api/v1/getMonthDishonoursday', params, {
          headers: {
            token: window.sessionStorage.getItem('token')
          }
        })
        result.then((res) => {
          console.log(res)
          this.disData = res.data.data.data
          this.Total = res.data.data.total
          for (let i = 0; i < this.disData.length; i++) {
            this.disData[i].updatetime = this.getTime(res.data.data.data[i].updatetime)
          }
        }, (error) => {
          console.log(error)
        })
      }
    },
    created() {
      this.getFuseData()
      console.log(this.$relv)
    },
    mounted() {

    }
  }
</script>

<style scoped>
</style>
