<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('balanceDetails.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <!-- 搜索框 -->
          <div class="deve_box">
            <!-- fb -->
            <div class="deve_img_box">
              <img src="@/images/svg/deve.svg" width="50%" />
              <!-- <img src="../images/fb.png" width="8%" style="float: left;margin-left: 8%;margin-top: 5%;" /> -->
            </div>
            <div class="deve_title">
              {{ $t("menu.shopping.tips") }}
            </div>
            <!-- <div class="deve_content">
                    <p>全球最大社交平台，月活跃度28.9亿</p>
                    <p>若您刚接触海外社交媒体营销，推荐您选择Facebook</p>
                    <P>无论您的客户是谁，都能找到他们</P>
                  </div>
                  <div style="width: 40%;margin: 0 auto;margin-bottom: 3%;margin-top: 5%;">
                    <el-button type="primary" style="width: 100%;font-size: .5em;" @click="dialogVisible = true">企业开户
                    </el-button>
                  </div> -->
            <!-- tk -->


          </div>
        </div>
      </el-col>
    </el-row>

  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: this.$t("menu.shopping.tips"),
      activeName: '1',
      dialogVisible: false
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>

<style scoped>
.deve_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}

.deve_img_box {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deve_content {
  font-size: .5em;
  margin-left: 18%;
  margin-top: 5%;
}

.deve_title {
  /* margin-left: 18%; */
  margin-top: 5%;
}
</style>
