<template>
  <div class="login_container">
    <el-row>
      <el-col :span="16">
        <div class="imgContainer">
          <img class="bg_dw" src="../images/04_dingwei.png" />
          <img class="bg_eg1" src="../images/04_eg1.png" />
          <img class="bg_eg2" src="../images/04_eg2.png" />
          <img class="bg_eg3" src="../images/04_eg3.png" />
        </div>
      </el-col>
      <el-col :span="8">
        <div class="login_box">
          <!-- 登录表单 -->
          <div class="login_title">{{ $t('forgot.title') }}</div>
          <!-- 注册，忘记密码区域 -->
          <div class="reg_link_box">
            <!-- 忘记密码 -->
            <div class="reg_left_box">
              <el-link type="primary" style="font-size: 15px;" @click="go_login()">{{ $t('forgot.backLogin') }}
                <svg t="1721649007314" class="pzh" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="3333" width="16" height="16">
                  <path
                    d="M855.1936 111.5648h-314.6752a28.5696 28.5696 0 0 0 0.0512 57.2416h314.6752c31.5904 0 57.1392 25.6 57.1392 57.1904v572.0064c0 31.5904-25.5488 57.1904-57.1392 57.1904h-314.6752a28.5696 28.5696 0 1 0 0 57.2416h314.6752c63.1808 0 114.3808-51.2 114.3808-114.3808V225.9968c0-63.1808-51.2-114.432-114.432-114.432z m0 0"
                    fill="#00A0E9" p-id="3334"></path>
                  <path
                    d="M788.3776 533.1968l-323.84 292.7104c-36.7616 33.28-95.5392 7.2192-95.5392-42.3936V666.624a7.168 7.168 0 0 0-7.2192-7.1168H82.9952a28.672 28.672 0 0 1-28.5696-28.7232V393.1136c0-15.872 12.8-28.6208 28.5696-28.6208h278.8352a7.168 7.168 0 0 0 7.2192-7.168V240.384c0-49.5616 58.7776-75.7248 95.5392-42.4448l323.84 292.7616a28.5184 28.5184 0 0 1-0.0512 42.496z m0 0"
                    fill="#00A0E9" p-id="3335"></path>
                </svg>
              </el-link>
            </div>
            <!-- 注册账号 -->
            <div class="reg_right_box">
              <el-link type="primary" style="font-size: 15px;" @click="go_reg()">{{ $t('forgot.signUpForAnAccount') }}
                <svg class="pzh" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <defs>
                    <rect id="path_0" x="0" y="0" width="16" height="16"></rect>
                  </defs>
                  <g opacity="1" transform="translate(0 0)  rotate(0 8 8)">
                    <rect fill="currentColor" opacity="1" transform="translate(0 0)  rotate(0 8 8)" x="0" y="0"
                      width="16" height="16"></rect>
                    <mask id="bg-mask" fill="white">
                      <use xlink:href="#path_0"></use>
                    </mask>
                    <g mask="url(#bg-mask)">
                      <path id="路径 23" style="stroke:#F3F7FB; stroke-width:1.4; stroke-opacity:1; stroke-dasharray:0 0"
                        transform="translate(6.055555555555657 4.5)  rotate(0 1.9444444444444446 3.5)"
                        d="M0,0L3.89,3.5L0,7 "></path>
                    </g>
                  </g>
                </svg></el-link>
            </div>
          </div>
          <div>
            <el-form :model="form_login" :rules="loginFormRules" ref="form_loginFormRef" style="margin-bottom: 30px;">
              <!-- 用户名 -->
              <el-form-item prop="username">
                <el-input class="mysearch" v-model="form_login.username" show-word-limit maxlength="11"
                  prefix-icon="iconfont icon-user" :placeholder="$t('forgot.des1')"></el-input>
              </el-form-item>
              <!-- 密码 -->
              <el-form-item prop="email">
                <el-input class="mysearch" v-model="form_login.email" prefix-icon="el-icon-message"
                  :placeholder="$t('forgot.des2')"></el-input>
              </el-form-item>
              <!-- 验证码  -->
              <div class="s-canvas">
                <el-form-item prop="code" class="codeInput">
                  <el-input class="mysearch" v-model="form_login.code" style="width: 58%;margin-top: 9px;"
                    prefix-icon="el-icon-chat-round" :placeholder="$t('forgot.des3')"></el-input>
                  <!-- 邮箱按钮 -->
                  <el-button type="primary" class="codeImg" @click="sendEmail" :disabled="sendAble"
                    :loading="sendAble">{{ countdown
                    }}</el-button>
                </el-form-item>
                <!-- 密码 -->
                <el-form-item prop="password">
                  <el-input class="mysearch" v-model="form_login.password" type="password" show-password
                    prefix-icon="iconfont icon-3702mima" :placeholder="$t('forgot.des4')"></el-input>
                </el-form-item>
                <!-- 再次输入密码 -->
                <el-form-item prop="rgpassword">
                  <el-input class="mysearch" v-model="form_login.rgpassword" type="password" show-password
                    prefix-icon="iconfont icon-3702mima" :placeholder="$t('forgot.des5')"></el-input>
                </el-form-item>
              </div>
              <!-- 登录按钮 -->
              <el-form-item>
                <el-button type="primary" v-no-more-click @click="changePwd" style="margin-top: 30px;">
                  {{ $t('forgot.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>
<script>
export default {
  data() {
    return {
      // 表单数据绑定对象
      form_login: {
        username: this.$cookie.get('user'),
        password: '',
        code: '',
        email: '',
        rgpassword: ''
      },
      countdown: this.$t('forgot.sendBtn'), // 倒计时
      // 表单验证规则
      loginFormRules: {
        // 验证验证码
        code: [{
          required: true,
          message: this.$t('forgot.coderule'),
          tigger: 'blur'
        }],
        // 验证用户名
        username: [{
          required: true,
          message: this.$t('forgot.userrule'),
          tigger: 'blur'
        },
        {
          min: 5,
          max: 12,
          message: this.$t('forgot.lengthrule'),
          trigger: 'blur'
        }
        ],

        email: [
          { required: true, message: this.$t('forgot.emailrule'), trigger: 'blur' },
        ],
        // 验证密码
        password: [{
          required: true,
          message: this.$t('forgot.pwdrule'),
          tigger: 'blur'
        },
        {
          min: 5,
          max: 15,
          message: this.$t('forgot.lengthrule'),
          trigger: 'blur'
        }, {
          pattern: '^[0-9a-zA-Z_]{1,}$',
          message: this.$t('forgot.abcrule')
        }
        ],
        rgpassword: [{
          required: true,
          message: this.$t('forgot.pwdrule'),
          tigger: 'blur'
        },
        {
          min: 5,
          max: 15,
          message: this.$t('forgot.lengthrule'),
          trigger: 'blur'
        }, {
          pattern: '^[0-9a-zA-Z_]{1,}$',
          message: this.$t('forgot.abcrule')
        }
        ]
      },
      sendAble: false, // 是否可发送邮箱
    }
  },
  methods: {
    // 修改新密码
    changePwd() {
      this.$refs.form_loginFormRef.validate(async valid => {
        if (!valid) return this.$message.error('请检查必填项!')
        const params = {
          pass: this.form_login.password,
          email: this.form_login.email,
          rpass: this.form_login.rgpassword,
          code: this.form_login.code,
          users: this.form_login.username
        }
        const result = this.$http.post('/api/v1/resetpass', params)
        result.then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg)
            this.go_login()
          } else {
            this.$message.error(res.data.msg)
          }
        }, (error) => {
          this.$message.error('登录错误，请重新登录!')
        })
      })
    },
    // 等待5分钟重新发生邮箱
    waitEmailTime() {
      this.sendAble = true
      let countdownSeconds = 300; // 设置倒计时时间，单位为秒
      this.countdown = countdownSeconds; // 将倒计时时间存储到组件的属性中
      const countdownInterval = setInterval(() => {
        this.countdown--; // 每秒减少一秒
        if (this.countdown <= 0) {
          clearInterval(countdownInterval); // 倒计时结束，清除定时器
          this.sendAble = false;
          this.countdown = this.$t('forgot.sendBtn')
        }
      }, 1000); // 每秒执行一次
    },
    // 发生邮箱
    sendEmail() {
      // this.$refs.form_loginFormRef.validate(async valid => {
      //   if (!valid) return this.$message.error('登录错误，请重新登录!')
      if (!this.form_login.email) return this.$message.error(this.$t('forgot.des2'))
      if (!this.form_login.username) return this.$message.error(this.$t('forgot.des1'))
      const params = {
        users: this.form_login.username,
        email: this.form_login.email
      }
      const result = this.$http.post('/api/v1/sendemail', params)
      result.then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg)
          this.waitEmailTime()
        } else {
          this.$message.error(res.data.msg)
        }
      }, (error) => {
        this.$message.error('登录错误，请重新登录!')
      })
      // })
    },
    // 跳转到注册页面
    go_reg() {
      this.$router.push('/register')
    },
    // 登录页面
    go_login() {
      this.$router.push('/')
    },

  },
  mounted: function () {

  },
  created() {

  }
}
</script>

<style lang="less" scoped>
.el-col,
.el-row {
  height: 100%;
}

.pzh {
  border-radius: 13px;
  position: relative;
  top: 3px;
}

.login_title {
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 5% 0;
  font-size: 1.5em;
  box-sizing: border-box;
  animation: mos 2s;
}

@keyframes mos {
  0% {
    opacity: 0;
  }

  35% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.saddd {
  width: 25px;
  height: 25px;
  position: relative;
  top: 1px;
  margin-right: 5px;
}

.login_container {
  height: 100%;
  width: 100%;
  background-image: url(../images/04_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position-x: 100%;
}

form {
  margin-top: 7% !important;
}

.login_box {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation: moveUpDown 2s;
  width: 500px;
  height: 650px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 24px;
  border: 1px solid rgba(85, 170, 238, 0.8);
  position: absolute;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 20px;

  .avatar_box {
    width: 130px;
    height: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #fff;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #fff;
    }
  }
}

@keyframes moveUpDown {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }

  100% {
    width: 500px;
    height: 650px;
    opacity: 1;
  }
}

button {
  width: 100%;
  height: 50px;
  font-size: 20px;
}

form {
  margin: 0% 5%;
  margin-top: 20%;
}

.reg_link_box div {
  display: inline;
}

.reg_right_box {
  position: absolute;
  right: 5%;
  animation: mos 3.3s;
  bottom: 5%;
}

.reg_left_box {
  position: absolute;
  left: 5%;
  bottom: 5%;
}

.codeRush {
  position: absolute;
  right: 5%;
  bottom: 28%;
  height: 12%;
  opacity: 0;
  width: 25%;
  z-index: 999;
}

.codeInput .el-input--prefix {
  width: 70%;
}

.codeImg {
  width: 40%;
  height: 55px;
  float: right;
  font-size: 16px;
  letter-spacing: 1.5px;
  border-radius: 10px;
  position: relative;
  top: 10px;
}

.el-form,
.el-divider,
.btn_xx {
  animation: mos 2s;
}

.mysearch /deep/ .el-input__inner {
  height: 55px !important;
  font-size: 15px !important;
  border-radius: 10px !important;
  background: transparent;
}


.imgContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.bg_dw {
  position: absolute;
  left: 21%;
  top: 31%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.bg_eg1 {
  position: absolute;
  left: 34%;
  top: 20%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 1.4s;
}

.bg_eg3 {
  position: absolute;
  left: 50%;
  top: 48%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 2.0s;
}

.bg_eg2 {
  position: absolute;
  left: 26%;
  top: 57%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 1.8s;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  100% {
    opacity: 1;
  }
}

/deep/ .el-input .el-input__count .el-input__count-inner {
  background: transparent;
}
</style>
