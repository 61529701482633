<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('partitioninglogs.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('partitioninglogs.title') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <!-- 搜索框 -->
          <div class="user_box">
            <el-form :model="form_fen">
              <!-- 输入卡号 -->
              <el-input style="width: 15%;" v-model="form_fen.users" prefix-icon="el-icon-user"
                :placeholder="$t('partitioninglogs.des1')">
              </el-input>
              <!-- 支付状态 -->
              <el-select v-model="fen_types_Val" :placeholder="$t('partitioninglogs.des2')" clearable>
                <el-option v-for="item in select_fen_types_Val" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <!-- 日期选择 -->
              <el-date-picker style="margin-right: 25px;" v-model="dataTimes" type="daterange" align="right"
                unlink-panels range-separator="-" :start-placeholder="$t('partitioninglogs.startDate')"
                :end-placeholder="$t('partitioninglogs.endDate')" :picker-options="pickerOptions2"
                format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker>
              <el-button type="primary" icon="el-icon-search"
                @click="getCustomdata()">{{ $t('partitioninglogs.search') }}</el-button>
              <el-button type="primary" icon="el-icon-refresh"
                @click="getCustomdata('1')">{{ $t('partitioninglogs.brushNew') }}</el-button>
            </el-form>
          </div>
          <div class="user_box">
            <!-- 客户管理表格 -->
            <el-table :data="tableData" style="width: 100%" border height="640" max-height="700" size="small"
              highlight-current-row>
              <el-table-column label="id" prop="id" v-if="false">
              </el-table-column>
              <el-table-column :label="$t('partitioninglogs.topUpYourUsername')" prop="fusers">
              </el-table-column>
              <el-table-column :label="$t('partitioninglogs.theAmountReceivedAfterTheTopUp')" prop="money">
              </el-table-column>
              <el-table-column :label="$t('partitioninglogs.itIsDividedIntoCurrencyTypes')" prop="types">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.types === 1">
                    CNY
                  </el-tag>
                  <el-tag v-else-if="scope.row.types === 2">
                    USD
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column :label="$t('partitioninglogs.theTypeOfTransaction')" prop="ctypes">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.ctypes === 1" type="success">
                    {{ $t('partitioninglogs.agentsShared') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.ctypes === 2" type="danger">
                    {{ $t('partitioninglogs.userWithdrawalDeduction') }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column :label="$t('partitioninglogs.splitAmount')" prop="fenmoney">
                <template slot-scope="scope">
                  <span v-if="scope.row.ctypes === 2" style="color: red;">
                    - {{ scope.row.fenmoney }}
                  </span>
                  <span v-else-if="scope.row.ctypes === 1">
                    {{ scope.row.fenmoney }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('partitioninglogs.divideTheTotalBalance')" prop="aftermoney">
              </el-table-column>
              <el-table-column :label="$t('partitioninglogs.splitExchangeRate')" prop="aftermoney">
                <template slot-scope="scope">
                  {{ scope.row.lv }}%
                </template>
              </el-table-column>
              <el-table-column :label="$t('partitioninglogs.divideIntoTime')" prop="createtime">
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="userTotal">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
const lodash = require('lodash')
export default {
  data() {
    return {
      fen_types_Val: '',
      select_fen_types_Val: [{
        value: '1',
        label: 'CNY'
      }, {
        value: '2',
        label: 'USD'
      }],
      name: '分成日记',
      form_record: {},
      recodeId: '',
      dataTimes: [],
      form_fen: {
        users: ''
      },
      userTotal: 0,
      userRecodTotal: 0,
      tableData: [],
      pickerOptions2: '',
      labelPosition: 'right',
      consumptionFormVisible: false,
      userRecodcurrentPage: 1, // 用户交易记录当前页码
      userRecodpageSize: 100, // 用户交易记录每页数据显示 条数
      // 充值渠道
      qdOptions: [{
        value: '1',
        label: '支付宝'
      }, {
        value: '2',
        label: '微信'
      }, {
        value: '3',
        label: '银行卡'
      }, {
        value: '4',
        label: '人工充值'
      }],
      rechageQd: '',
      // 用户交易记录
      userRecod: [],
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      pickerOptions2: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  methods: {
    // 格式化时间
    getTime( /** timestamp=0 **/) {
      const ts = arguments[0] || 0
      let t, y, m, d, h, i, s
      t = ts ? new Date(ts * 1000) : new Date()
      y = t.getFullYear()
      m = t.getMonth() + 1
      d = t.getDate()
      h = t.getHours()
      i = t.getMinutes()
      s = t.getSeconds()
      // 可依据须要在这里定义时间格式
      return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10

        ?
        '0' + d :
        d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (i < 10 ? '0' + i : i) + ':' + (s < 10 ? '0' + s : s)
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getCustomdata()
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getCustomdata()
    },
    // 获取返点日记
    getCustomdata(rf) {
      if (rf == '1') {
        this.currentPage = 1
        this.pageSize = 100
        this.fen_types_Val = ''
        this.form_fen.users = ''
        this.dataTimes = []
      }
      if (this.dataTimes[0] == undefined && this.dataTimes[1] == undefined) {
        this.dataTimes[0] = ""
        this.dataTimes[1] = ""
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        types: this.fen_types_Val,
        users: this.form_fen.users,
        start: this.dataTimes[0],
        ends: this.dataTimes[1]
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/getMyFenData', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        this.tableData = res.data.data.data
        this.userTotal = res.data.data.total
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].createtime = this.getTime(res.data.data.data[i].createtime)
        }
      }, (error) => {
        console.log(error)
      })
    }
  },
  created() {
    this.getCustomdata()
  },
  mounted() {

  }
}
</script>

<style scoped>
.el-input,
.el-select {
  margin-right: 1%;
}

.el-form {
  margin-bottom: 20px;
}
</style>
