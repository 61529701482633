<template>
  <div ref="threeconatiner" class="earth-c"></div>
</template>
<script>
import * as THREE from "three";
import TWEEN from "@tweenjs/tween.js";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import world from "./world.json";
export default {
  components: {},
  mixins: [],
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      cube: null,
      group: null,
      obj: { R: 10 },
      pos: {},
    };
  },
  created() {
    // this.xyFormLaglng3D();
  },
  mounted() {
    this.init();
  },
  methods: {
    // 经纬度坐标转球面坐标
    lon2xyz(R, longitude, latitude) {
      let lon = (longitude * Math.PI) / 180; //转弧度值
      let lat = (latitude * Math.PI) / 180; //转弧度值
      lon = -lon; // three.js坐标系z坐标轴对应经度-90度，而不是90度
      // 经纬度坐标转球面坐标计算公式
      const x = R * Math.cos(lat) * Math.cos(lon);
      const y = R * Math.sin(lat);
      const z = R * Math.cos(lat) * Math.sin(lon);
      // 返回球面坐标
      return {
        x,
        y,
        z,
      };
    },
    // 获取geojson 转换坐标
    xyFormLatlng3D() {
      const { features } = world;
      features.forEach((fe) => {
        if (fe.geometry.type === "MultiPolygon") {
          let codr = fe.geometry.coordinates;
          codr.forEach((f) => {
            let points = [];
            if (f.length) {
              f[0].forEach((point) => {
                let { x, y, z } = this.lon2xyz(10, point[0], point[1]);
                points.push(x, y, z);
              });
              if (points.length) {
                // 拿到转换后的坐标
                // 顶点坐标
                let vertors = new Float32Array(points);
                // 创建图像
                const geo = new THREE.BufferGeometry();
                geo.setAttribute(
                  "position",
                  new THREE.BufferAttribute(vertors, 3)
                );
                // 材质
                const material = new THREE.LineBasicMaterial({
                  color: "#67748e",
                  linewidth: 2,
                  linecap: "round", //ignored by WebGLRenderer
                  linejoin: "round", //ignored by WebGLRenderer
                });
                // 线段
                const line = new THREE.Line(geo, material);
                this.group.add(line);
              }
            }
          });
        } else if (fe.geometry.type === "Polygon") {
          let codr = fe.geometry.coordinates;
          codr.forEach((f) => {
            let points = [];
            f.forEach((point) => {
              let { x, y, z } = this.lon2xyz(10, point[0], point[1]);
              points.push(x, y, z);
            });
            if (points.length) {
              // 拿到转换后的坐标
              // 顶点坐标
              let vertors = new Float32Array(points);
              // 创建图像
              const geo = new THREE.BufferGeometry();
              geo.setAttribute(
                "position",
                new THREE.BufferAttribute(vertors, 3)
              );
              // 材质
              const material = new THREE.LineBasicMaterial({
                color: "#67748e",
                linewidth: 2,
                linecap: "round", //ignored by WebGLRenderer
                linejoin: "round", //ignored by WebGLRenderer
              });
              // 线段
              const line = new THREE.Line(geo, material);
              this.group.add(line);
            }
          });
        }
      });
      this.scene.add(this.group);
    },
    // 添加扩散光圈
    addCirclePoint() {
      // 添加圆圈
      var geometry = new THREE.PlaneGeometry(1, 1); // 矩形平面
      // TextureLoader创建一个纹理加载器对象，可以加载图片作为几何体纹理
      var textureLoader = new THREE.TextureLoader();
      // 执行load方法，加载纹理贴图成功后，返回一个纹理对象Texture
      //       textureLoader.load(require('@/assets/光圈贴图.png'), (texture) => {
      //         var material = new THREE.MeshLambertMaterial({
      //           // 设置颜色纹理贴图：Texture对象作为材质map属性的属性值
      //           map: texture, // 设置颜色贴图属性值
      //           side: THREE.DoubleSide,
      //           transparent: true // 使用背景透明的png贴图，注意开启透明计算
      //         }) // 材质对象Material
      //         let mesh = new THREE.Mesh(geometry, material)
      //         var size = Math.random() * 3 + 2 // 2~5之间随机,表示mesh.size缩放倍数
      //         mesh.scale.set(size, size, size) // 设置mesh大小
      //         const pos = this.lon2xyz(10, 110.1532, 31.155);
      //         mesh.position.set(pos.x,pos.y,pos.z) // 设置mesh位置
      //         this.group.add(mesh)
      //         // 添加光源
      //         const light = new THREE.PointLight(0xffffff, 100, 200);
      //         light.position.set(0, 0, 0);
      //         this.scene.add(light);
      //         console.log('%c [ 成功 ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', '成功')
      //  })
    },
    // 发光点
    addlightCube() {
      // 渲染发光点
      const conegeometry = new THREE.ConeGeometry(0.2, 0.8, 4);
      const conematerial = new THREE.MeshBasicMaterial({
        color: "#049ef4",
        wireframe: true,
        // transparent: true,
        // opacity: 0.6,
      });
      const cone = new THREE.Mesh(conegeometry, conematerial);
      // 位置
      const { x, y, z } = this.lon2xyz(10.3, 110.1532, 31.155);
      cone.position.set(x, y, z);
      // 让圆锥体朝向原点
      // 计算向量AB
      // 计算圆心到圆锥体的方向向量
      var direction = new THREE.Vector3();
      direction
        .subVectors(new THREE.Vector3(0, 0, 0), cone.position)
        .normalize();

      // 设置圆锥体的旋转
      cone.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), direction);
      // 计算第二个移动点

      this.group.add(cone);
      const pos = this.lon2xyz(10, 110.1532, 31.155);
      // 创建Tween动画对象
      const tween = new TWEEN.Tween(cone.position); //创建一段tween动画
      //经过2000毫秒，pos对象的x和y属性分别从零变化为100、50
      tween
        .to({ x: pos.x, y: pos.y, z: pos.z }, 1000)
        .yoyo(true) // 设置为来回循环
        .repeat(Infinity); // 无限重复动画;
      //tween动画开始执行
      tween.start();
      // 添加光圈
    },
    init() {
      // 创建场景、相机和渲染器
      this.scene = new THREE.Scene();
      this.group = new THREE.Group();
      this.camera = new THREE.PerspectiveCamera(
        40,
        this.width / this.height,
        1,
        1000
      );
      this.renderer = new THREE.WebGLRenderer({ alpha: true }); // 设置渲染器透明背景
      this.renderer.setPixelRatio(window.devicePixelRatio);

      this.renderer.setSize(this.width, this.height);
      this.$refs.threeconatiner.appendChild(this.renderer.domElement);
      const controls = new OrbitControls(this.camera, this.renderer.domElement);
      controls.minDistance = 20;
      // controls.maxDistance = 50;
      // 禁用缩放功能
      controls.enableZoom = false;
      controls.maxPolarAngle = Math.PI / 2;

      // 创建立方体并添加到场景中
      const geometry = new THREE.SphereGeometry(this.obj.R, 64, 32);
    //   TextureLoader创建一个纹理加载器对象，可以加载图片作为几何体纹理
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(require("@/components/cavas/tt/hb.png"), (texture) => {
          const material = new THREE.MeshLambertMaterial({
            // 设置颜色纹理贴图：Texture对象作为材质map属性的属性值
            map: texture, // 设置颜色贴图属性值
            side: THREE.DoubleSide,
            transparent: true // 使用背景透明的png贴图，注意开启透明计算
          }); // 材质对象Material
          this.cube = new THREE.Mesh(geometry, material);
          this.group.add(this.cube);

        },(e) => {
          console.log('%c [ e ]-233', 'font-size:13px; background:pink; color:#bf2c9f;', e)

        });
    //   const material = new THREE.MeshBasicMaterial({
    //     color: "#049ef4",
    //     // transparent: true,
    //     // opacity: 1,
    //   });
    //   this.cube = new THREE.Mesh(geometry, material);
    //   this.group.add(this.cube);
      // 添加光源
      // 创建环境光
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      this.scene.add(ambientLight);
      // 设置相机位置
      this.camera.position.set(15, 15, 20);
      this.camera.lookAt(new THREE.Vector3(0, 0, 0));
      this.scene.add(this.group);
      this.animate();
    },
    animate() {
      TWEEN.update();
      requestAnimationFrame(this.animate);
      // this.cube.rotation.x += 0.01;
      this.group.rotation.y += 0.005;
      this.renderer.render(this.scene, this.camera);
    },
  },
};
</script>
<style lang="less" scoped></style>
