<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <!-- 时间选择器 -->
            <!-- <el-input v-model="userNames" placeholder="请输入用户名" ></el-input> -->
             <el-date-picker
                v-model="monthValue"
                value-format="yyyy-MM-dd"
                type="month"
                style="width: 15%;margin-right: 30px;"
                @change="getFuseData"
                :clearable="false"
                placeholder="选择月">
            </el-date-picker>
            <el-button type="primary" style="margin-bottom: 20px;margin-left: 20px;" icon="el-icon-search"
              @click="getFuseData()">查 询</el-button>
            <el-button type="primary" v-no-more-click icon="el-icon-refresh" @click="getNowDate">重 置
            </el-button>
            <el-table :data="disData" border style="width: 100%">
              <el-table-column prop="nums" label="卡头">
                <template slot-scope="scope">
                  <el-tag effect="dark">{{ scope.row.nums }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="lv" label="拒付率" sortable  :sort-method="(a,b)=>{return a.lv - b.lv}">
                <template slot-scope="scope">
                  <el-tag type="danger" effect="dark">{{ scope.row.lv }}%</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="allnum" label="总交易笔数">
              </el-table-column>
              <el-table-column prop="errnum" label="失败笔数">
              </el-table-column>
              <el-table-column prop="renums" label="拒付笔数">
              </el-table-column>
              <el-table-column prop="allmoney" label="总消费金额">
              </el-table-column>
              <el-table-column prop="renmoney" label="失败金额">
              </el-table-column>
              <el-table-column prop="errmoney" label="拒付金额">
              </el-table-column>
              <el-table-column prop="updatetime" label="更新时间"> </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
  export default {
    data() {
      return {
        name: '每月数据',
        disData: [],
        currentPage: 1,
        pageSize: 10,
        Total: 0,
        monthValue:"", // 月份选择
        defaultValue:new Date()
      }
    },
    methods: {
        // 获取当前月份
        getNowDate(){
           const n =  new Date()
            const y = n.getFullYear()
            let m = n.getMonth() + 1
            if(m < 10){
                m = "0"+m
            }
            this.monthValue = `${y}-${m}-01`
            // 获取数据
            this.getFuseData()
        },
      // 格式化时间
      getTime( /** timestamp=0 **/ ) {
        const ts = arguments[0] || 0
        let t, y, m, d, h, i, s
        t = ts ? new Date(ts * 1000) : new Date()
        y = t.getFullYear()
        m = t.getMonth() + 1
        d = t.getDate()
        h = t.getHours()
        i = t.getMinutes()
        s = t.getSeconds()
        return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (
          i < 10 ? '0' + i : i) + ':' + (s < 10 ? '0' + s : s)
      },
      getFuseData() {
        console.log('%c [this.month]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', this.monthValue)
        let params = {
            begin_time:new Date(this.monthValue).getTime()/1000
        }
        params = this.$lmjm(params)
        const result = this.$http.post('/api/v1/getMonthDishonoursday', params, {
          headers: {
            token: window.sessionStorage.getItem('token')
          }
        })
        result.then((res) => {
          console.log(res)
          this.disData = res.data.data.data
          this.Total = res.data.data.total
          for (let i = 0; i < this.disData.length; i++) {
            this.disData[i].updatetime = this.getTime(res.data.data.data[i].updatetime)
          }
        }, (error) => {
          console.log(error)
        })
      }
    },
    created() {
        // 获取当前月
       this.getNowDate()
       
      console.log(this.$relv)
    },
    mounted() {

    }
  }
</script>

<style scoped>
</style>
