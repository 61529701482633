<template>
    <div class="con">
        <div class="now-data-myself">
            <div class="now-data-myself-time">{{ date }}</div>
            <div class="now-data-myself-week">{{ week }}</div>
        </div>
        <Calendar v-on:choseDay="clickDay" v-on:changeMonth="changeDate" v-on:enterDay="enterDay"
            v-on:isToday="clickToday" :markDate="markDate">
        </Calendar>
        <!-- 假期 -->
        <div class="holiday" v-show="holidayList.length > 0">
            <!-- title -->
            <div class="holiday-title">当月假期</div>
            <!-- content -->
            <div class="holiday-content">
                <div class="holiday-content-item" v-for="(d, index) in holidayList" :key="d.data">
                    <div class="left_point">
                    </div>
                    <div class="right_content">{{ d.date }}-{{ d.cnweekday }},{{ d.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Calendar from 'vue-calendar-component';
export default {
    components: {
        Calendar
    },
    data() {
        return {
            date: "",
            week: "",
            markDate: [],
            holidayList: [], // 假期列表
        };
    },
    created() {
        var now = new Date();
        this.date = now.getDate();//得到日期
        var day = now.getDay();//得到周几
        const year = now.getFullYear()
        const month = now.getMonth()
        const currentMonth = year + '/' + (month + 1) + '/' + '1'
        // this.getHoliday(currentMonth)
        var arr_week = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
        this.week = arr_week[day];
    },
    methods: {
        // 请求国内假期
        getHoliday(date) {
            this.markDate = []
            this.holidayList = []
            const params = {
                key: '8fbd36d49aaaf956463bd57d819ccdff',
                date: date,
                type: '2'
            }
            const result = this.$http.post("https://apis.tianapi.com/jiejiari/index", params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
            });
            result.then(
                (res) => {
                    if (res.data.code == 200) {
                        res.data.result.list.forEach(item => {
                            if (item.info == '节假日') {
                                this.markDate.push(item.date.replaceAll('-', '/'))
                                this.holidayList.push(item)
                            }
                        })
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        clickDay(data) {
            console.log(data); //选中某天
        },
        changeDate(data) {
            console.log(data); //左右点击切换月份
            const changeMonth = data.slice(0, -1) + '1'
            console.log('changeMonth: ', changeMonth);
            // this.getHoliday(changeMonth)
        },
        clickToday(data) {
            console.log(data); // 跳到了本月
        },
        enterDay(date) {
            console.log('date: ', date);
        }
    },
};
</script>

<style lang="less" scoped>
* {
    box-sizing: border-box;
}

.now-data-myself {
    width: 40%;
    position: absolute;
    padding-left: 25px;
}

.now-data-myself-time {
    color: #F56C6C;
    font-size: 28px;
    height: 30px;
    font-family: "Helvetica Neue";
}

.now-data-myself-week {
    font-size: 10px;
    color: #909399;
}

.con {
    padding: 8px;
    min-width: 280px;
}

.holiday {
    background-color: #FAFAFA;
    padding: 0 10px 10px 10px;

    &-title {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #555;
        font-weight: 600;
        border-bottom: 1px solid #eee;
    }

    &-content {
        height: 210px;
        overflow-y: auto;

        &-item {
            display: flex;
            align-items: center;
            height: 70px;
            border-bottom: 1px solid #eee;
        }

        .left_point {
            position: relative;
            width: 3%;
            height: 50px;
        }

        .left_point::before {
            position: absolute;
            top: 4px;
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: rosybrown;
        }

        .right_content {
            margin-left: 3%;
            width: 94%;
            height: 50px;
            font-size: 14px;
            color: #788186;
        }
    }

    &-content::-webkit-scrollbar {
        width: 0;
    }
}

/deep/ .wh_content_all[data-v-2d6e7ffc] {
    background-color: #fff !important;
}

/deep/ .wh_item_date[data-v-2d6e7ffc],
.wh_top_tag[data-v-2d6e7ffc] {
    font-family: 'pingfang';
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

/deep/ .wh_content_item[data-v-2d6e7ffc],
.wh_content_item_tag[data-v-2d6e7ffc] {
    font-family: 'pingfang';
    font-size: 14px;
    font-weight: 500;
    color: #7a91a7;
}

/deep/ .wh_content_item[data-v-2d6e7ffc] .wh_isToday[data-v-2d6e7ffc] {
    background: #E3EEFD;
    // border-radius: 0;
}

/deep/ .wh_content_item[data-v-2d6e7ffc] .wh_chose_day[data-v-2d6e7ffc] {
    background: transparent;
    border-radius: 0;
}

/deep/ .wh_item_date[data-v-2d6e7ffc]:hover {
    background: #E3EEFD;
}

/deep/ .wh_jiantou1[data-v-2d6e7ffc] {
    border-top: 2px solid #000;
    border-left: 2px solid #000;
}

/deep/ .wh_jiantou2[data-v-2d6e7ffc] {
    border-top: 2px solid #000;
    border-right: 2px solid #000;
}

/deep/ .wh_top_changge[data-v-2d6e7ffc] li {
    color: #000;
    font-size: 16px;
}

/deep/ .wh_top_changge[data-v-2d6e7ffc] {
    display: flex;
    width: 160px;
    float: right;
}

/deep/ .wh_content_item[data-v-2d6e7ffc]>.wh_isMark {
    background: #d59bd1;
    border-radius: 0;
}
</style>