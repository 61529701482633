<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('eCommerceCustomerAcquisition.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('eCommerceCustomerAcquisition.title') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <div class="header_btn_group">
              <!-- 操作事件 -->
              <el-input style="width: 200px" v-model="query"
                :placeholder="$t('eCommerceCustomerAcquisition.des1')"></el-input>
              <div class="btn_box_item btn_next" @click="searchData()">
                {{ $t('eCommerceCustomerAcquisition.search') }}
              </div>
              <div class="btn_box_item btn_next" @click="searchData('1')">
                {{ $t('eCommerceCustomerAcquisition.brushNew') }}
              </div>
              <div class="btn_add" @click="openForm">{{ $t('eCommerceCustomerAcquisition.applyForAnAdvertisement') }}
              </div>
            </div>
          </div>
          <!-- 表格 + 分页 -->
          <div class="user_box">
            <el-table :data="tableData" style="width: 100%" height="660" max-height="700" size="small"
              highlight-current-row>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.reviewStatus')" fixed="left" align="center"
                width="140" prop="status_audit">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status_audit == 1" type="success">
                    {{ $t('eCommerceCustomerAcquisition.approved') }}
                  </el-tag>
                  <el-tag v-if="scope.row.status_audit == 0" type="warning">
                    {{ $t('eCommerceCustomerAcquisition.underReview') }}
                  </el-tag>

                  <div v-if="scope.row.status_audit == -1">
                    <el-tag type="danger"> {{ $t('eCommerceCustomerAcquisition.reviewRejection') }} </el-tag>
                    <el-tooltip class="item" effect="dark" :content="scope.row.reason" placement="top">
                      <i class="el-icon-warning" style="font-size: 16px; margin-left: 8px"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.taskStatus')" align="center" fixed="left"
                width="140" prop="status_run">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status_run == 0" type="warning">
                    {{ $t('eCommerceCustomerAcquisition.didnTStart') }}
                  </el-tag>
                  <div class="openRunImage_box" v-if="scope.row.status_run == 1" @click="viewResultImg">
                    <img src="@/images/svg/adv/run.svg" alt="" srcset="" />
                    <el-image ref="previewImg" style="width: 60px; height: 60px" v-show="false"
                      :src="scope.row.resultUrl[0]" :preview-src-list="scope.row.resultUrl">
                    </el-image>
                    {{ $t('eCommerceCustomerAcquisition.screenshotOfTheTask') }}
                  </div>
                  <el-tag v-if="scope.row.status_run == 2" type="danger">
                    {{ $t('eCommerceCustomerAcquisition.pause') }}
                  </el-tag>
                  <el-tag v-if="scope.row.status_run == -1" type="danger">
                    {{ $t('eCommerceCustomerAcquisition.comeToAnEnd') }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.passwordIdentificationNumber')" align="center"
                width="140" prop="adv_sn">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.deliveryMode')" align="center" width="140"
                prop="push_type">
                <template slot-scope="scope">
                  <div v-if="scope.row.push_type == 1">{{ $t('eCommerceCustomerAcquisition.flowRate') }}</div>
                  <div v-if="scope.row.push_type == 2">{{ $t('eCommerceCustomerAcquisition.sales') }}</div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.theTypeOfImpression')" align="center"
                width="140" prop="push_target">ow_type">
                <template slot-scope="scope">
                  <div v-if="scope.row.show_type == 1">{{ $t('eCommerceCustomerAcquisition.site') }}</div>
                  <div v-if="scope.row.show_type == 2">{{ $t('eCommerceCustomerAcquisition.informationApplications') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.targetStrategy')" align="center" width="140"
                prop="target">
                <template slot-scope="scope">
                  <div v-if="scope.row.target == 1">{{ $t('eCommerceCustomerAcquisition.maximizeConversions') }}</div>
                  <div v-if="scope.row.target == 2">{{ $t('eCommerceCustomerAcquisition.maximizeConversionValue') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.startDate')" align="center" width="140"
                prop="begin_date">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.startTime')" align="center" width="140"
                prop="begin_time">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.endDate')" align="center" width="140"
                prop="end_date">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.endTime')" align="center" width="140"
                prop="end_time">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.deliveryBudget')" align="center" width="140"
                prop="day_price">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.numberOfDaysForDuration')" align="center"
                width="140" prop="day">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.totalBudget')" align="center" width="140"
                prop="total_price">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.totalCost')" align="center" width="140"
                prop="total_amount">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.country')" align="center" width="140"
                prop="push_country">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.launchCity')" align="center" width="140"
                prop="push_address">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.ageOfTheTargetGroup')" align="center"
                width="140" prop="person_age">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.genderOfTheTargetGroup')" align="center"
                width="140" prop="person_sex">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.language')" align="center" width="140"
                prop="language">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.adHeadline')" align="center" width="140"
                prop="adv_title" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.descriptionOfTheAd')" align="center" width="140"
                prop="adv_description" :show-overflow-tooltip="true">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.advertisingImages')" align="center" width="140"
                prop="adv_image">
                <template slot-scope="scope">
                  <el-image style="width: 44px; height: 44px" :src="scope.row.coverUrl[0]"
                    :preview-src-list="scope.row.coverUrl">
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.theUrlOfTheAdvertisingWebsite')" align="center"
                width="140" prop="adv_web" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <a :href="scope.row.adv_web" target="_blank" rel="noopener noreferrer">{{ scope.row.adv_web }}</a>
                </template>
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.whatsAppContacts')" align="center" width="140"
                prop="whatsapp">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.creationTime')" align="center" width="140"
                prop="createtime">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.updated')" align="center" width="140"
                prop="updatetime">
              </el-table-column>
              <el-table-column :label="$t('eCommerceCustomerAcquisition.operation')" align="center" fixed="right"
                width="180">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="$t('eCommerceCustomerAcquisition.des2')"
                    placement="top">
                    <el-button size="small" type="text" v-if="scope.row.status_audit != -1"
                      :disabled="scope.row.status_audit != -1">{{ $t('eCommerceCustomerAcquisition.editorial')
                      }}</el-button>
                  </el-tooltip>
                  <el-button size="small" type="text" v-if="scope.row.status_audit == -1"
                    @click="editorForm(scope.row)">{{ $t('eCommerceCustomerAcquisition.editorial') }}</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totals">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 申请广告弹窗 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" @close="closeDialog" width="50%" top="5vh">
      <div class="frist_card">
        <!-- section1 投放模式 -->
        <div class="frist_card-modal" v-if="modalStatus == 1">
          <div class="frist_card-modal_cover">
            <img src="@/images/fb_d.png" alt="" srcset="" />
            <div class="frist_card-modal_cover_title">
              <div>{{ $t('eCommerceCustomerAcquisition.des3') }}</div>
              <div>{{ $t('eCommerceCustomerAcquisition.des4') }}</div>
            </div>
          </div>
          <div class="frist_card-modal_title">
            <img src="@/images/svg/adv/modul.svg" alt="" srcset="" />
            {{ $t('eCommerceCustomerAcquisition.des5') }}
          </div>
          <div class="frist_card-modal_select">
            <div :class="[
              infoContent.push_type == 1
                ? 'frist_card-modal_select_cell cell-active'
                : 'frist_card-modal_select_cell',
            ]" @click="selectModul(1)">
              <div class="frist_card-modal_select_cell_left">
                <img src="@/images/svg/adv/ll.svg" alt="" srcset="" />
              </div>
              <div class="frist_card-modal_select_cell_right">
                {{ $t('eCommerceCustomerAcquisition.des6') }}
              </div>
            </div>
            <div :class="[
              infoContent.push_type == 2
                ? 'frist_card-modal_select_cell cell-active'
                : 'frist_card-modal_select_cell',
            ]" @click="selectModul(2)">
              <div class="frist_card-modal_select_cell_left">
                <img src="@/images/svg/adv/xl.svg" alt="" srcset="" />
              </div>
              <div class="frist_card-modal_select_cell_right">
                {{ $t('eCommerceCustomerAcquisition.des7') }}
              </div>
            </div>
          </div>
          <div class="frist_card-modal_title">
            <img src="@/images/svg/adv/type.svg" alt="" srcset="" />
            {{ $t('eCommerceCustomerAcquisition.theTypeOfImpression') }}
          </div>
          <div class="frist_card-modal_switch">
            <div class="frist_card-modal_switch_check">
              <div class="frist_card-modal_switch_check_left">
                <el-switch v-model="infoContent.show_type" active-color="#434a74" inactive-color="#ebecf0"
                  :active-value="1" :inactive-value="2">
                </el-switch>
              </div>
              <div class="frist_card-modal_switch_check_right">
                {{ $t('eCommerceCustomerAcquisition.websiteDelivery') }}
              </div>
            </div>
            <div class="frist_card-modal_switch_check">
              <div class="frist_card-modal_switch_check_left">
                <el-switch v-model="infoContent.show_type" active-color="#434a74" inactive-color="#ebecf0"
                  :active-value="2" :inactive-value="1">
                </el-switch>
              </div>
              <div class="frist_card-modal_switch_check_right">
                {{ $t('eCommerceCustomerAcquisition.appInformation') }}
              </div>
            </div>
          </div>
          <div class="frist_card-modal_title">
            <img src="@/images/svg/adv/target.svg" alt="" srcset="" />
            {{ $t('eCommerceCustomerAcquisition.targetStrategy') }}
          </div>
          <div class="frist_card-modal_select">
            <el-select v-model="infoContent.target" style="width: 100%"
              :placeholder="$t('eCommerceCustomerAcquisition.pleaseSelectATargetPolicy')">
              <el-tooltip class="item" effect="light" :content="$t('eCommerceCustomerAcquisition.des8')"
                placement="left">
                <el-option :label="$t('eCommerceCustomerAcquisition.maximizeConversions')" :value="1"> </el-option>
              </el-tooltip>

              <el-tooltip class="item" effect="light" :content="$t('eCommerceCustomerAcquisition.des9')"
                placement="left">
                <el-option :label="$t('eCommerceCustomerAcquisition.maximizeConversionValue')" :value="2"> </el-option>
              </el-tooltip>
            </el-select>
          </div>
        </div>
        <!-- section2  -->
        <div class="second_card-modal" v-if="modalStatus == 2">
          <!-- 目标群体 -->
          <div class="second_card-modal_title">{{ $t('eCommerceCustomerAcquisition.budgetForAdServing') }}</div>
          <div class="second_card-modal_input">
            <el-input v-model="infoContent.day_price"
              :placeholder="$t('eCommerceCustomerAcquisition.advertisingBudget')" style="width: 25%"
              @change="changeUsd"><template slot="prepend">＄</template></el-input>
            <el-input v-model="service_adv" style="width: 25%" disabled><template slot="append">{{
              $t('eCommerceCustomerAcquisition.premium') }}</template></el-input>
            <el-input v-model="total_adv" style="width: 25%" disabled><template slot="append">{{
              $t('eCommerceCustomerAcquisition.totalBudget') }}</template></el-input>
            <el-input v-model="userUsd" style="width: 25%" disabled><template slot="append">{{
              $t('eCommerceCustomerAcquisition.balance') }}</template></el-input>
          </div>
          <!-- 目标群体 -->
          <div class="second_card-modal_title">{{ $t('eCommerceCustomerAcquisition.theNumberOfDaysTheAdHasBeenRunning')
            }}
          </div>
          <div class="second_card-modal_input">
            <el-input v-model="infoContent.day" style="width: 100%" prefix-icon="el-icon-watch-1"
              :placeholder="$t('eCommerceCustomerAcquisition.des10')" @change="dayChange"></el-input>
          </div>
          <!-- 开始时间 -->
          <div class="second_card-modal_title">{{ $t('eCommerceCustomerAcquisition.startTime') }}</div>
          <div class="second_card-modal_time">
            <div style="display: flex; justify-content: space-between">
              <el-date-picker style="width: 49%" type="date" :placeholder="$t('eCommerceCustomerAcquisition.des11')"
                :picker-options="expireTimeOPtion" v-model="infoContent.begin_date" format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd" @change="changeDate">
              </el-date-picker>
              <el-time-picker style="width: 49%" v-model="infoContent.begin_time" value-format="HH-mm-ss"
                :placeholder="$t('eCommerceCustomerAcquisition.des12')" @change="changeTime">
              </el-time-picker>
            </div>
          </div>
          <!-- 结束时间 -->
          <div class="second_card-modal_title">{{ $t('eCommerceCustomerAcquisition.endTime') }}</div>
          <div class="second_card-modal_time">
            <div style="display: flex; justify-content: space-between">
              <el-date-picker style="width: 49%" type="date" :placeholder="$t('eCommerceCustomerAcquisition.endDate')"
                v-model="infoContent.end_date" format="yyyy 年 MM 月 dd 日" disabled value-format="yyyy-MM-dd">
              </el-date-picker>
              <el-time-picker style="width: 49%" v-model="infoContent.end_time" value-format="HH-mm-ss" disabled
                :placeholder="$t('eCommerceCustomerAcquisition.endTime')">
              </el-time-picker>
            </div>
          </div>
          <!-- 投放地区 -->
          <div class="second_card-modal_title">{{ $t('eCommerceCustomerAcquisition.region') }}</div>
          <div class="second_card-modal_input">
            <div style="display: flex; justify-content: space-between">
              <el-tooltip class="item" effect="dark" :content="$t('eCommerceCustomerAcquisition.des13')"
                placement="top">
                <el-input style="width: 49%" prefix-icon="el-icon-location-outline" v-model="infoContent.push_country"
                  :placeholder="$t('eCommerceCustomerAcquisition.des14')"></el-input>
              </el-tooltip>

              <el-tooltip class="item" effect="dark" :content="$t('eCommerceCustomerAcquisition.des15')"
                placement="top">
                <el-input style="width: 49%" prefix-icon="el-icon-position" v-model="infoContent.push_address"
                  :placeholder="$t('eCommerceCustomerAcquisition.des16')"></el-input>
              </el-tooltip>
            </div>
          </div>
          <!-- 目标群体 -->
          <div class="second_card-modal_title">{{ $t('eCommerceCustomerAcquisition.genderOfTheTargetGroup') }}</div>
          <div class="second_card-modal_input">
            <el-select v-model="infoContent.person_sex" prefix-icon="el-icon-user" style="width: 100%"
              :placeholder="$t('eCommerceCustomerAcquisition.des17')">
              <el-option :label="$t('eCommerceCustomerAcquisition.random')"
                :value="$t('eCommerceCustomerAcquisition.random')"> </el-option>
              <el-option :label="$t('eCommerceCustomerAcquisition.man')"
                :value="$t('eCommerceCustomerAcquisition.man')">
              </el-option>
              <el-option :label="$t('eCommerceCustomerAcquisition.woman')"
                :value="$t('eCommerceCustomerAcquisition.woman')"> </el-option>
            </el-select>
          </div>
          <!-- 目标群体 -->
          <div class="second_card-modal_title">{{ $t('eCommerceCustomerAcquisition.des18') }}</div>
          <div class="second_card-modal_input">
            <el-input v-model="infoContent.person_age" prefix-icon="el-icon-pie-chart" style="width: 100%"
              :placeholder="$t('eCommerceCustomerAcquisition.des19')"></el-input>
          </div>
          <!-- 语言 -->
          <div class="second_card-modal_title">{{ $t('eCommerceCustomerAcquisition.deliveryLanguage') }}</div>
          <div class="second_card-modal_input">
            <el-input v-model="infoContent.language" prefix-icon="el-icon-news" style="width: 100%"
              :placeholder="$t('eCommerceCustomerAcquisition.des20')"></el-input>
          </div>
        </div>
        <!-- section3 -->
        <div class="three_card-modal" v-if="modalStatus == 3">
          <div class="three_card-modal_cover">
            <el-upload action="#" list-type="picture-card" :http-request="handleUpload"
              accept="image/jpeg, image/png, image/jpg" :limit="3" :before-upload="uploadFile"
              :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="fileList">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :append-to-body="true" top="5vh" :visible.sync="dialogImageVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
          <!-- 广告标题 -->
          <div class="three_card-modal_title">{{ $t('eCommerceCustomerAcquisition.adHeadline') }}</div>
          <div class="three_card-modal_input">
            <el-input type="textarea" :rows="2" maxlength="130" show-word-limit v-model="infoContent.adv_title"
              :placeholder="$t('eCommerceCustomerAcquisition.des21')"></el-input>
          </div>
          <!-- 广告描述 -->
          <div class="three_card-modal_title">{{ $t('eCommerceCustomerAcquisition.descriptionOfTheAd') }}</div>
          <div class="three_card-modal_input">
            <el-input type="textarea" :rows="4" maxlength="500" show-word-limit
              :placeholder="$t('eCommerceCustomerAcquisition.des22')" v-model="infoContent.adv_description"></el-input>
          </div>
          <!-- 联系方式 -->
          <div class="three_card-modal_title">{{ $t('eCommerceCustomerAcquisition.serveYourWebsite') }}</div>
          <div class="three_card-modal_input">
            <el-input v-model="infoContent.adv_web" :placeholder="$t('eCommerceCustomerAcquisition.des23')"></el-input>
          </div>
          <!-- 联系方式 -->
          <div class="three_card-modal_title">{{ $t('eCommerceCustomerAcquisition.contact') }}</div>
          <div class="three_card-modal_input">
            <el-input v-model="infoContent.whatsapp" :placeholder="$t('eCommerceCustomerAcquisition.des24')"></el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn_box">
          <div class="btn_box_item" @click="dialogVisible = false">{{ $t('eCommerceCustomerAcquisition.cancel') }}</div>
          <div class="btn_box_item btn_next" @click="lastStep" v-if="modalStatus > 1">
            <i class="el-icon-arrow-left"></i>{{ $t('eCommerceCustomerAcquisition.previous') }}
          </div>
          <div class="btn_box_item btn_next" @click="nectStep" v-if="modalStatus < 3">
            <i class="el-icon-arrow-right"></i>{{ $t('eCommerceCustomerAcquisition.next') }}
          </div>
          <div class="btn_box_item btn_next" @click="createAD()" v-if="modalStatus == 3 && formStatus == 'add'">
            <i class="el-icon-plus"></i>{{ $t('eCommerceCustomerAcquisition.apply') }}
          </div>
          <div class="btn_box_item btn_next" @click="editorAD()" v-if="modalStatus == 3 && formStatus == 'editor'">
            <i class="el-icon-plus"></i>{{ $t('eCommerceCustomerAcquisition.apply') }}
          </div>
        </div>
      </span>
      <span slot="title">
        <div>
          <img style="width: 36px; height: 36px" src="@/images/fb.png" alt="" srcset="" />
          {{ $t('eCommerceCustomerAcquisition.eCommerceGoesOverseas') }}
        </div>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { put, signatureUrl, getFileNameUUID } from "@/utils/alioss.js";
import { log } from "mathjs";
export default {
  data() {
    return {
      totals: 0,
      name: "电商获客",
      selelctRechargeStatusVal: "",
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      dataTimes: [],
      labelPosition: "right",
      statusVal: "",
      tableData: [],
      dialogVisible: false, // 广告申请弹窗
      modalStatus: 1, // 申请广告流程
      dialogImageUrl: "",
      dialogImageVisible: false,
      fileList: [], // 文件列表
      query: "", // 广告查询参数
      service_adv: "", // 广告手续费
      formStatus: "add", //
      infoContent: {
        adv_sn: "", // 口令-识别
        push_type: "", // 投放模式 1.流量 2.销量
        show_type: "", // 展示广告 1.网站 2.信息应用
        target: "", // 成绩目标 1.转化量最大化 2.转化价值最大化
        day: 5, // 持续几天
        begin_date: "", // 开始日期
        end_date: "", // 结束日期
        begin_time: "", // 开始时间
        end_time: "", // 结束时间
        day_price: "", // 单日预算
        push_country: "", // 投放国国家
        push_address: "", // 投放地区 国家 城市 支持多个
        person_age: "", // 目标群体年龄范围
        person_sex: "", // 目标群体性别范围
        language: "英语", // 语言 默认英语
        adv_title: "", // 广告标题
        adv_description: "", // 广告描述
        adv_image: "", // 广告图片/视频地址
        adv_web: "", // 广告网站URL
        whatsapp: "", // WhatsApp联系方式
      },
      userUsd: "", // 用户余额
      expireTimeOPtion: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
    };
  },
  computed: {
    total_adv: function () {
      if (
        this.infoContent.day &&
        this.service_adv &&
        this.infoContent.day_price
      ) {
        const adv = parseFloat(this.service_adv.replaceAll("%", ""));
        const total =
          this.infoContent.day_price * this.infoContent.day +
          (adv / 100) * this.infoContent.day_price * this.infoContent.day;
        if (total > this.userUsd) {
          this.$message.error({
            message: "余额不足",
            type: "error",
            duration: 3000,
          });
        }
        return total;
      } else {
        return "";
      }
    },
  },
  methods: {
    // 天数更新的时候判断时间是否需要更新
    dayChange(val) {
      console.log("val: ", val);
      console.log("this.infoContent.begin_date: ", this.infoContent.begin_date);

      if (val && this.infoContent.begin_date) {
        console.log(222);
        this.changeDate(this.infoContent.begin_date);
      }
    },
    // 投放模式
    selectModul(value) {
      this.infoContent.push_type = value;
    },
    // 改变开始日期
    changeDate(value) {
      if (!this.infoContent.day) {
        this.$message.error({
          message: "请先选择天数",
          type: "error",
          duration: 3000,
        });
        this.infoContent.end_date = "";
        return;
      }
      if (value) {
        console.log("value: ", value);
        let d = new Date(value);
        console.log("d: ", d);
        d.setDate(d.getDate() + parseInt(this.infoContent.day));
        let year = d.getFullYear(); // 获取年份
        let month = d.getMonth() + 1; // 获取月份（注意月份是从0开始计数的，所以要加1）
        let day = d.getDate(); // 获取日期

        // 格式化月份和日期为两位数
        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        let formattedDate = year + "-" + month + "-" + day;
        this.infoContent.end_date = formattedDate;
      }
    },
    // 改变时间
    changeTime(value) {
      if (value) {
        this.infoContent.end_time = value;
      }
    },
    // 编辑表单
    editorForm(row) {
      // 处理文件
      let fileList = JSON.parse(row.adv_image);
      this.fileList = fileList;
      this.infoContent = row;
      this.dialogVisible = true;
      this.formStatus = "editor";
    },
    viewResultImg() {
      this.$refs.previewImg.showViewer = true;
    },
    // 生成广告口令
    generateRandomString(length) {
      const characters =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters.charAt(randomIndex);
      }
      return result;
    },
    getFileName(name) {
      return name.substring(name.lastIndexOf(".")); //.txt
    },
    uploadFile(file) {
      console.log("this.fileList.length: ", this.fileList.length);
      if (this.fileList.length == 3) {
        this.$message({
          type: "warning",
          message: "最多只能上传3张图片",
        });
        return;
      }
      return file;
    },
    handleUpload(option) {
      var obj = option.file.name;
      var index = obj.lastIndexOf(".");
      obj = obj.substring(index, obj.length);
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName = getFileNameUUID() + this.getFileName(option.file.name);
      put("/ADIamge/" + option.file.name, option.file).then((res) => {
        // 上传成功之后，转换真实的地址
        signatureUrl("/ADIamge/" + option.file.name).then((resSign) => {
          this.fileList.push({
            name: objName,
            url: resSign.slice(0, resSign.indexOf("?")),
            status: "success",
          });
        });
      });
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    closeDialog() {
      this.fileList = [];
      this.infoContent = {
        adv_sn: "", // 口令-识别
        push_type: "", // 投放模式 1.流量 2.销量
        show_type: "", // 展示广告 1.网站 2.信息应用
        target: "", // 成绩目标 1.转化量最大化 2.转化价值最大化
        day: 5, // 持续几天
        begin_date: "", // 开始日期
        end_date: "", // 结束日期
        begin_time: "", // 开始时间
        end_time: "", // 结束时间
        day_price: "", // 单日预算
        push_address: "", // 投放地区 国家 城市 支持多个
        person_age: "", // 目标群体年龄范围
        person_sex: "", // 目标群体性别范围
        language: "英语", // 语言 默认英语
        adv_title: "", // 广告标题
        adv_description: "", // 广告描述
        adv_image: "", // 广告图片/视频地址
        adv_web: "", // 广告网站URL
        whatsapp: "", // WhatsApp联系方式
      };
      this.modalStatus = 1;
      this.formStatus = "add";
      this.dialogVisible = false;
    },
    // 打开弹窗
    openForm() {
      this.getHui();
      this.infoContent = {
        adv_sn: "", // 口令-识别
        push_type: "", // 投放模式 1.流量 2.销量
        show_type: "", // 展示广告 1.网站 2.信息应用
        target: "", // 成绩目标 1.转化量最大化 2.转化价值最大化
        day: 5, // 持续几天
        begin_date: "", // 开始日期
        end_date: "", // 结束日期
        begin_time: "", // 开始时间
        end_time: "", // 结束时间
        day_price: "", // 单日预算
        push_address: "", // 投放地区 国家 城市 支持多个
        person_age: "", // 目标群体年龄范围
        person_sex: "", // 目标群体性别范围
        language: "英语", // 语言 默认英语
        adv_title: "", // 广告标题
        adv_description: "", // 广告描述
        adv_image: "", // 广告图片/视频地址
        adv_web: "", // 广告网站URL
        whatsapp: "", // WhatsApp联系方式
      };
      this.dialogVisible = true;
    },
    changeUsd(val) {
      console.log("val: ", val);
      if (parseInt(val) < 5) {
        this.$message.warning("投放预算最低单日5美元!");
        this.infoContent.day_price = "5";
      }
    },
    // 获取用户信息
    getUser() {
      const result = this.$http.post(
        "/api/v1/getuserinfos",
        {},
        {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        }
      );
      result.then((res) => {
        this.service_adv = res.data.data.service_adv;
        this.service_adv = this.service_adv + "%";
      });
    },
    // 上一步
    lastStep() {
      this.modalStatus--;
    },
    // 下一步
    nectStep() {
      if (
        this.modalStatus == 2 &&
        this.infoContent.begin_time &&
        this.infoContent.end_time &&
        this.infoContent.day_price &&
        this.infoContent.push_address &&
        this.infoContent.person_age &&
        this.infoContent.person_sex &&
        this.infoContent.language
      ) {
        this.modalStatus++;
        return;
      }
      if (
        this.modalStatus == 1 &&
        this.infoContent.push_type &&
        this.infoContent.show_type &&
        this.infoContent.target
      ) {
        this.modalStatus++;
        return;
      }
      this.$message({
        message: "请填写完整信息",
        type: "warning",
      });
    },
    // 编辑广告
    editorAD() {
      if (
        this.modalStatus == 3 &&
        this.infoContent.adv_title &&
        this.infoContent.adv_description &&
        this.infoContent.adv_web &&
        this.infoContent.person_age &&
        this.infoContent.whatsapp &&
        this.fileList.length > 0
      ) {
        // 生成口令
        this.infoContent.adv_sn = this.generateRandomString(10);
        // 创建文件
        this.infoContent.adv_image = JSON.stringify(this.fileList);
        // 申请广告
        let params = this.infoContent;
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/reApplyAdvert", params, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.dialogVisible = false;
            this.searchData();
          },
          (error) => {
            console.log(error);
          }
        );
        return;
      }
      this.$message({
        message: "请填写完整信息",
        type: "warning",
      });
    },
    // 申请广告
    createAD() {
      if (
        this.modalStatus == 3 &&
        this.infoContent.adv_title &&
        this.infoContent.adv_description &&
        this.infoContent.adv_web &&
        this.infoContent.person_age &&
        this.infoContent.whatsapp &&
        this.fileList.length > 0
      ) {
        // 生成口令
        this.infoContent.adv_sn = this.generateRandomString(10);
        // 创建文件
        this.infoContent.adv_image = JSON.stringify(this.fileList);
        // 申请广告
        let params = this.infoContent;
        params = this.$lmjm(params);
        const result = this.$http.post("/api/v1/applyAdvert", params, {
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        });
        result.then(
          (res) => {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.dialogVisible = false;
            this.searchData();
          },
          (error) => {
            console.log(error);
          }
        );
        return;
      }
      this.$message({
        message: "请填写完整信息",
        type: "warning",
      });
    },
    getHui() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getHuiUsdcharInfo", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.userUsd = res.data.data.usd;
            // this.form_hw.user = res.data.data.username;
            // this.form_hw.usd = res.data.data.usd;
            // this.form_hw.service_char = res.data.data.service_char;
            // this.form_hw.usdlv = res.data.data.usdlv;
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 搜索
    searchData(rf) {
      if (rf == "1") {
        this.currentPage = 1;
        this.pageSize = 100;
        this.query = "";
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        adv_sn: this.query,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getMyAdvertList", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tableData = res.data.data.data;
          this.totals = res.data.data.total;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
            this.tableData[i].updatetime = this.getTime(
              res.data.data.data[i].updatetime
            );
            const file = JSON.parse(res.data.data.data[i].adv_image);
            this.tableData[i].coverUrl = file.map((item) => item.url);

            const file_result = res.data.data.data[i].result_image
              ? JSON.parse(res.data.data.data[i].result_image)
              : [];
            this.tableData[i].resultUrl = file_result.map((item) => item.url);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 格式化时间
    getTime() {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts / 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getCardDetails();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCardDetails();
    },
  },
  created() {
    this.searchData();
    this.getUser();
  },
  mounted() { },
};
</script>

<style lang="less" scoped>
/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: #ff0080;
}

/deep/ .el-select .el-input__inner:focus {
  border-color: #ff0080;
}

/deep/ .el-input--prefix {
  margin-right: 0% !important;
}

/deep/ .el-input__inner:focus {
  border-color: #ff0080;
}

/deep/ .el-dialog {
  border-radius: 0px;
}

/deep/ .el-dialog__body {
  padding: 12px 20px;
}

.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-range-editor {
  margin-right: 3%;
}

.el-button {
  margin-right: 2%;
}

.user_box {
  .header_btn_group {
    display: flex;
    align-items: center;

    .btn_box_item {
      border-radius: 4px;
      margin-left: 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      width: 120px;
      height: 38px;
      transition: all 2ms ease-out;
      background-image: linear-gradient(310deg, #141727, #3a416f);
      box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11),
        0 2px 4px -1px rgba(0, 0, 0, 0.07);
    }

    .btn_box_item:hover {
      transform: translateY(-1.5px);
    }

    .btn_box_item:active {
      transform: translateY(1.5px);
    }

    .btn_next {
      background-image: linear-gradient(310deg, #7928ca, #ff0080);
      color: #fff;
    }

    .btn_add {
      margin-left: 36px;
      position: relative;
      width: 120px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      /* 强制大写 */
      text-decoration: none;
      font-family: sans-serif;
      box-sizing: border-box;
      background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
      background-size: 400%;
      border-radius: 4px;
      z-index: 1;
    }

    .btn_add:hover {
      animation: animate 8s linear infinite;
    }

    @keyframes animate {
      0% {
        background-position: 0%;
      }

      100% {
        background-position: 400%;
      }
    }

    .btn_add:before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      z-index: -1;
      background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
      background-size: 300%;
      border-radius: 40px;
      opacity: 0;
      transition: 0.5s;
    }

    .btn_add:hover:before {
      filter: blur(20px);
      /*模糊效果*/
      opacity: 1;
      animation: animate 8s linear infinite;
    }
  }
}

.frist_card {
  width: 100%;
}

.frist_card-modal {
  width: 100%;

  // background-color: #03a9f4;
  &_cover {
    display: flex;
    // border: 1px solid orange;
    border-radius: 8px;
    margin-bottom: 36px;

    img {
      width: 50%;
      height: 230px;
    }

    &_title {
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      font-size: 28px;
      color: #fff;
      font-weight: 800;
      // background-image: linear-gradient(310deg, #7928ca, #ff0080);
      background-image: url("@/images/tk_d.png");
      background-size: 100% 100%;
    }

    &_title div:nth-child(1) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &_title div:nth-child(2) {
      margin-top: 12px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &_title {
    margin-bottom: 30px;
    font-size: 14px;

    img {
      width: 18px;
      height: 18px;
    }
  }

  &_switch {
    margin-bottom: 36px;

    &_check {
      margin-top: 12px;
      display: flex;
      align-items: center;
      margin-left: 12px;

      &_right {
        margin-left: 12px;
        color: #67748e;
        // font-size: 18px;
        // font-weight: 800;
      }
    }
  }

  &_select {
    display: flex;
    justify-content: space-around;
    margin-bottom: 24px;

    &_cell {
      cursor: pointer;
      display: flex;
      width: 42%;
      height: 80px;
      border-radius: 2px;
      transition: all 200ms ease-out;
      box-shadow: 4px 2px 6px 2px rgba(0, 0, 0, 0.2);

      &_left {
        margin-left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;

        img {
          width: 58px;
          height: 58px;
        }
      }

      &_right {
        margin-left: 8px;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &_cell:hover {
      background-color: rgba(212, 35, 122, 1);
      color: #fff;
      transform: scale(1.1);
      /* 悬停时的旋转和缩放效果 */
    }

    .cell-active {
      background-color: rgb(212, 35, 122);
      color: #fff;
    }
  }
}

.second_card-modal {
  width: 100%;

  &_title {
    margin-bottom: 12px;
    font-size: 14px;

    img {
      width: 22px;
      height: 22px;
    }
  }

  &_time {
    margin-bottom: 16px;
  }

  &_input {
    margin-bottom: 16px;
  }

  &_cover {
    border: 1px solid orange;
    border-radius: 8px;
    margin-bottom: 12px;

    img {
      width: 100%;
      height: 180px;
    }
  }
}

.three_card-modal {
  width: 100%;

  &_title {
    margin-bottom: 4px;
    font-size: 14px;
  }

  &_time {
    margin-bottom: 16px;
  }

  &_input {
    margin-bottom: 16px;
  }

  &_cover {
    // border: 1px solid orange;
    border-radius: 8px;
    margin-bottom: 12px;

    img {
      width: 100%;
      height: 180px;
    }

    .upload-demo {
      width: 100%;
    }
  }
}

.btn_box {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  .btn_box_item {
    border-radius: 2px;
    margin-left: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    width: 130px;
    height: 38px;
    transition: all 2ms ease-out;
    background-image: linear-gradient(310deg, #141727, #3a416f);
    box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11),
      0 2px 4px -1px rgba(0, 0, 0, 0.07);
  }

  .btn_box_item:hover {
    transform: translateY(-1.5px);
  }

  .btn_box_item:active {
    transform: translateY(1.5px);
  }

  .btn_next {
    background-image: linear-gradient(310deg, #7928ca, #ff0080);
    color: #fff;
  }
}

.openRunImage_box {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7928ca;

  img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
}
</style>
