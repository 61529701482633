<template>
  <div class="login_container">
    <el-row>
      <el-col :span="16">
        <!-- <img src="../images/banner.png" style="position: relative;left: 10%;top: 8%;" /> -->
        <div class="imgContainer">
          <img class="bg_dw" src="../images/04_dingwei.png" />
          <img class="bg_eg1" src="../images/04_eg1.png" />
          <img class="bg_eg2" src="../images/04_eg2.png" />
          <img class="bg_eg3" src="../images/04_eg3.png" />
        </div>

      </el-col>
      <el-col :span="8">
        <div class="login_box">
          <!-- 登录表单 -->
          <div class="login_title">{{ $t('login.logInToYourInstantAccount') }}</div>
          <!-- 注册，忘记密码区域 -->
          <div class="reg_link_box">
            <!-- 忘记密码 -->
            <div class="reg_left_box">
              <el-link type="primary" style="font-size: 15px;" @click="go_fg()">{{ $t('login.ForgotPassword') }}
                <svg t="1721647265880" class="pzh" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="1484" width="16" height="16">
                  <path
                    d="M512.512 1.472A510.848 510.848 0 0 0 1.728 512.384a510.784 510.784 0 0 0 510.784 510.912 510.912 510.912 0 0 0 0-1021.76z m-14.72 807.68a41.408 41.408 0 0 1-41.088-41.664c0-23.04 18.368-41.664 41.088-41.664 22.656 0 41.088 18.624 41.088 41.6 0 23.04-18.432 41.728-41.088 41.728z m171.904-366.464c-12.032 21.632-35.456 47.744-70.336 78.72-23.424 20.8-38.656 36.032-45.888 45.824a100.608 100.608 0 0 0-15.936 33.92c-2.752 10.304-4.672 25.984-5.568 46.784a35.072 35.072 0 0 1-34.24 43.648 35.2 35.2 0 0 1-34.688-28.096h-0.64c-0.32-9.536-0.384-16.704-0.384-21.504 0-28.224 3.904-52.48 11.968-72.96 5.888-15.552 15.36-31.04 28.288-46.72 9.6-11.52 26.88-28.224 51.712-50.176 24.896-21.952 41.088-39.488 48.576-52.48 7.424-13.056 11.136-27.264 11.136-42.752 0-27.904-10.944-52.48-32.768-73.6a110.272 110.272 0 0 0-80.128-31.744c-30.72 0-56.192 9.6-76.736 28.8-19.52 18.304-32.64 46.4-39.296 84.48a37.376 37.376 0 0 1-37.632 36.288 37.376 37.376 0 0 1-36.096-47.808c7.552-50.752 26.944-89.792 58.24-117.312 32.832-28.992 76.288-43.52 130.24-43.52 57.28 0 102.912 15.552 136.96 46.72 34.112 31.104 51.072 68.736 51.072 112.896 0 25.536-5.888 49.088-17.92 70.592z"
                    fill="#4F85F8" p-id="1485"></path>
                </svg>
              </el-link>
            </div>
            <!-- 注册账号 -->
            <div class="reg_right_box">
              <el-link type="primary" style="font-size: 15px;" @click="go_reg()">{{ $t('login.signUpForAnAccount') }}
                <svg class="pzh" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <defs>
                    <rect id="path_0" x="0" y="0" width="16" height="16"></rect>
                  </defs>
                  <g opacity="1" transform="translate(0 0)  rotate(0 8 8)">
                    <rect fill="currentColor" opacity="1" transform="translate(0 0)  rotate(0 8 8)" x="0" y="0"
                      width="16" height="16"></rect>
                    <mask id="bg-mask" fill="white">
                      <use xlink:href="#path_0"></use>
                    </mask>
                    <g mask="url(#bg-mask)">
                      <path id="路径 23" style="stroke:#F3F7FB; stroke-width:1.4; stroke-opacity:1; stroke-dasharray:0 0"
                        transform="translate(6.055555555555657 4.5)  rotate(0 1.9444444444444446 3.5)"
                        d="M0,0L3.89,3.5L0,7 "></path>
                    </g>
                  </g>
                </svg></el-link>
            </div>
          </div>
          <div v-if="isGG">
            <el-form :model="form_login" :rules="loginFormRules" ref="form_loginFormRef" style="margin-bottom: 30px;">
              <!-- 用户名 -->
              <el-form-item prop="username">
                <el-input class="mysearch" v-model="form_login.username" show-word-limit maxlength="11"
                  prefix-icon="iconfont icon-user" :placeholder="$t('login.des1')"></el-input>
              </el-form-item>
              <!-- 密码 -->
              <el-form-item prop="password">
                <el-input class="mysearch" v-model="form_login.password" prefix-icon="iconfont icon-3702mima"
                  type="password" :placeholder="$t('login.des2')"></el-input>
              </el-form-item>
              <!-- 验证码  -->
              <div class="s-canvas">
                <el-form-item prop="code" class="codeInput">
                  <el-input class="mysearch" v-model="form_login.code" style="width: 58%;margin-top: 9px;"
                    prefix-icon="el-icon-picture" :placeholder="$t('login.des3')"></el-input>
                  <img class="codeImg" :src="codeSrc" @click="getCodeUrl()" />
                </el-form-item>
              </div>
              <!-- 登录按钮 -->
              <el-form-item>
                <el-button type="primary" v-no-more-click @click="login('form_login')" style="margin-top: 30px;">
                  {{ $t('login.login') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="!isGG">
            <el-form :rules="gg_loginFormRules" ref="gg_form_loginFormRef" :model="GG_login"
              style="margin-bottom: 30px;">
              <!-- 用户名 -->
              <el-form-item prop="user">
                <el-input class="mysearch" v-model="GG_login.user" show-word-limit maxlength="11"
                  prefix-icon="iconfont icon-user" :placeholder="$t('login.des1')"></el-input>
              </el-form-item>
              <!-- 密码 -->
              <el-form-item prop="codes">
                <el-input class="mysearch" v-model="GG_login.codes" prefix-icon="iconfont icon-3702mima"
                  :placeholder="$t('login.des4')"></el-input>
              </el-form-item>
              <!-- 登录按钮 -->
              <el-form-item v-if="isGG">
                <el-button type="primary" v-no-more-click @click="login('form_login')" style="margin-top: 30px;">{{
                  $t('login.login') }}
                </el-button>
              </el-form-item>
              <!-- 谷歌登录按钮 -->
              <el-form-item v-if="!isGG">
                <el-button type="primary" v-no-more-click @click="GGlogin()" style="margin-top: 30px;">{{
                  $t('login.login') }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-divider><span style="font-size: 18px;">{{ $t('login.otherWays') }}</span></el-divider>
          <div style="text-align: center;" class="btn_xx" v-if="isGG">
            <el-button style="width: 90%;" @click="ggl()">
              <svg t="1683712608882" class="saddd" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="2442" width="200" height="200">
                <path
                  d="M213.1976 515.482c0-33.485 5.632-65.588 15.616-95.693L53.4536 287.155A511.053 511.053 0 0 0 0.0016 515.482c0 82.048 19.2 159.436 53.35 228.147L228.6606 610.765c-10.24-30.72-15.437-62.9-15.463-95.283"
                  fill="#FBBC05" p-id="2443"></path>
                <path
                  d="M521.2426 210.944a303.36 303.36 0 0 1 191.872 67.891l151.629-149.913C772.3536 49.306 653.9006 0.077 521.2436 0.077c-205.978 0-383.028 116.659-467.79 287.078L228.9416 419.79c40.397-121.498 155.597-208.87 292.352-208.87"
                  fill="#EA4335" p-id="2444"></path>
                <path
                  d="M523.4706 813.235c-137.473 0-253.236-86.81-293.889-207.565L53.3256 737.51c85.172 169.37 263.092 285.261 470.144 285.261 127.719 0 249.677-44.39 341.248-127.641L697.3706 768.46c-47.18 29.108-106.65 44.8-173.952 44.8"
                  fill="#34A853" p-id="2445"></path>
                <path
                  d="M1023.4376 510.618c0-30.26-4.787-62.874-11.904-93.133H523.4186v197.888h280.935c-14.004 67.481-52.224 119.347-106.957 153.088L864.7176 895.13c96.18-87.373 158.72-217.55 158.72-384.512"
                  fill="#4285F4" p-id="2446"></path>
              </svg>
              <span style="position: relative;top: -4px;">{{ $t('login.googleLogin') }}</span>
            </el-button>
          </div>
          <div style="text-align: center;" class="btn_xx" v-if="!isGG">
            <el-button style="width: 90%;" @click="isGG = true">
              <svg t="1685538102812" class="saddd" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="8274" width="200" height="200">
                <path
                  d="M512 1022.1056c-282.3168 0-511.232-228.864-511.232-511.0784S229.632 0 512 0c282.3168 0 511.232 228.8128 511.232 511.0272 0 282.2656-228.864 511.0784-511.232 511.0784z m0-926.2592a415.2832 415.2832 0 0 0-415.3856 415.232c0 107.2128 41.0624 204.6464 107.8272 278.3232 60.16-29.1328 38.0416-4.9152 116.736-37.2736 80.5888-33.1264 99.6352-44.6464 99.6352-44.6464l0.768-76.288s-30.1568-22.8864-39.5264-94.72c-18.8928 5.4272-25.088-22.016-26.2144-39.424-1.024-16.896-10.9568-69.4784 12.0832-64.7168-4.7104-35.1744-8.0896-66.8672-6.4-83.6608 5.7344-58.88 62.976-120.5248 151.0912-124.9792 103.68 4.4544 144.7424 66.048 150.528 124.928 1.6384 16.8448-2.048 48.5376-6.7584 83.6096 23.04-4.6592 13.0048 47.872 11.8784 64.7168-1.024 17.408-7.3728 44.7488-26.2144 39.3728-9.4208 71.7824-39.5776 94.464-39.5776 94.464l0.7168 75.9296s19.0976 10.8032 99.6352 43.9296c78.6944 32.3584 56.576 9.5744 116.736 38.7584a413.184 413.184 0 0 0 107.776-278.3744A415.232 415.232 0 0 0 512 95.7952z"
                  fill="#4EB0FA" p-id="8275"></path>
              </svg>
              <span style="position: relative;top: -4px;">{{ $t('login.accountLogin') }}</span>
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>
<script>
export default {
  data() {
    const ckUser = this.$cookie.get('user')
    return {
      codeSrc: '',
      isGG: true,
      GG_login: {
        user: this.$cookie.get('user'),
        codes: ''
      },
      // 表单数据绑定对象
      form_login: {
        uuid: '',
        username: this.$cookie.get('user'),
        password: '',
        code: '',
        token: ''
      },
      // 表单验证规则
      loginFormRules: {
        // 验证验证码
        code: [{
          required: true,
          message: this.$t('login.coderule'),
          tigger: 'blur'
        }],
        // 验证用户名
        username: [{
          required: true,
          message: this.$t('login.userrule'),
          tigger: 'blur'
        },
        {
          min: 5,
          max: 12,
          message: this.$t('login.lengthrule'),
          trigger: 'blur'
        }
        ],
        // 验证密码
        password: [{
          required: true,
          message: this.$t('login.pwdrule'),
          tigger: 'blur'
        },
        {
          min: 5,
          max: 15,
          message: this.$t('login.lengthrule'),
          trigger: 'blur'
        }, {
          pattern: '^[0-9a-zA-Z_]{1,}$',
          message: this.$t('login.abcrule')
        }
        ]
      },
      gg_loginFormRules: {
        // 验证用户名
        user: [{
          required: true,
          message: this.$t('login.userrule'),
          tigger: 'blur'
        },
        {
          min: 5,
          max: 12,
          message: this.$t('login.lengthrule'),
          trigger: 'blur'
        }
        ],
        // 验证验证码
        codes: [{
          required: true,
          message: this.$t('login.coderule'),
          tigger: 'blur'
        }, {
          min: 6,
          max: 6,
          message: this.$t('login.ggrule'),
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    ggl() {
      this.isGG = false
    },
    // 跳转到注册页面
    go_reg() {
      this.$router.push('/register')
    },
    // 跳转到忘记密码页面
    go_fg() {
      this.$router.push('/forgotPW')
    },

    GGlogin() {
      let logins = {
        users: this.GG_login.user,
        code: this.GG_login.codes
      }
      logins = this.$lmjm(logins)
      this.$refs.gg_form_loginFormRef.validate(async valid => {
        if (!valid) return this.$message.error('登录错误，请重新登录!')
        const result = this.$http.post('/api/v1/cclogin', logins)
        result.then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            // 获得成功响应返回的数据
            this.$message({
              message: '登录成功!',
              type: 'success',
              duration: '800'
            })
            this.form_login.token = res.data.data.token
            window.sessionStorage.setItem('token', this.form_login.token)
            this.$cookie.set('user', this.form_login.username)
            document.cookie = 'isShow=true'
            this.VerifyToken(window.sessionStorage.getItem('token'))
          } else {
            this.$message.error(res.data.msg)
            this.getCodeUrl()
          }
        }, (error) => {
          this.$message.error('登录错误，请重新登录!')
          this.getCodeUrl()
        })
      })
    },
    login(form_login) {
      let logins = {
        users: this.form_login.username,
        pass: this.form_login.password,
        code: this.form_login.code.toLowerCase(),
        codeid: this.uuid
      }
      logins = this.$lmjm(logins)
      this.$refs.form_loginFormRef.validate(async valid => {
        if (!valid) return this.$message.error('登录错误，请重新登录!')
        const result = this.$http.post('/api/v1/login', logins)
        result.then((res) => {
          if (res.data.code === 200) {
            let params = {}
            params = this.$lmjm(params)
            const result = this.$http.post('/api/v1/getuserinfos', params, {
              headers: {
                token: res.data.data.token
              }
            })
            result.then((res) => {
              // 获得成功响应返回的数据
              this.$message({
                message: '欢迎，' + res.data.data.realname + "。",
                type: 'success',
                duration: '3000'
              })
            }, (error) => {
              console.log(error)
            })
            this.form_login.token = res.data.data.token
            window.sessionStorage.setItem('token', this.form_login.token)
            this.$cookie.set('user', this.form_login.username)
            document.cookie = 'isShow=true'
            this.VerifyToken(window.sessionStorage.getItem('token'))
          } else {
            this.$message.error(res.data.msg)
            this.getCodeUrl()
          }
        }, (error) => {
          this.$message.error('登录错误，请重新登录!')
          this.getCodeUrl()
        })
      })
    },
    getCodeUrl() {
      let params = {}
      params = this.$lmjm(params)
      const result = this.$http.get('/api/v1/captcha?' + 'key=' + params.key + '&noncestr=' + params.noncestr + '&sign=' + params.sign + '&timestmap=' + params.timestmap)
      result.then((res) => {
        if (res.status === 200) {
          // 获得成功响应返回的数据
          this.codeSrc = res.data.data.codeurl
          this.uuid = res.data.data.codeid
        }
      }, (error) => {
        console.log('错误:' + error)
        this.getCodeUrl()
      })
    }
  },
  mounted: function () {

  },
  created() {
    this.getCodeUrl()
    this.VerifyToken(window.sessionStorage.getItem('token'))

    function add_chatinline() {
      const hccid = 44569780
      const nt = document.createElement('script')
      nt.async = true
      nt.src = 'https://mylivechat.com/chatinline.aspx?hccid=' + hccid
      const ct = document.getElementsByTagName('script')[0]
      ct.parentNode.insertBefore(nt, ct)
    }
    add_chatinline()
  }
}
</script>

<style lang="less" scoped>
.el-col,
.el-row {
  height: 100%;
}

.pzh {
  border-radius: 13px;
  position: relative;
  top: 3px;
}

.login_title {
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 10% 0;
  font-size: 1.5em;
  box-sizing: border-box;
  animation: mos 2s;
}

@keyframes mos {
  0% {
    opacity: 0;
  }

  35% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.saddd {
  width: 25px;
  height: 25px;
  position: relative;
  top: 1px;
  margin-right: 5px;
}

.login_container {
  height: 100%;
  width: 100%;
  background-image: url(../images/04_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position-x: 100%;
}

form {
  margin-top: 7% !important;
}

.login_box {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation: moveUpDown 2s;
  width: 500px;
  height: 650px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 24px;
  border: 1px solid rgba(85, 170, 238, 0.8);
  position: absolute;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 20px;

  .avatar_box {
    width: 130px;
    height: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #fff;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #fff;
    }
  }
}

@keyframes moveUpDown {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0;
  }

  100% {
    width: 500px;
    height: 650px;
    opacity: 1;
  }
}

button {
  width: 100%;
  height: 50px;
  font-size: 20px;
}

form {
  margin: 0% 5%;
  margin-top: 20%;
}

.reg_link_box div {
  display: inline;
}

.reg_right_box {
  position: absolute;
  right: 5%;
  animation: mos 3.3s;
  bottom: 5%;
}

.reg_left_box {
  position: absolute;
  left: 5%;
  bottom: 5%;
}

.codeRush {
  position: absolute;
  right: 5%;
  bottom: 28%;
  height: 12%;
  opacity: 0;
  width: 25%;
  z-index: 999;
}

.codeInput .el-input--prefix {
  width: 70%;
}

.codeImg {
  width: 40%;
  height: 50px;
  float: right;
  border-radius: 5px;
  border: 2px solid #eee;
  position: relative;
  top: 10px;
}

.el-form,
.el-divider,
.btn_xx {
  animation: mos 2s;
}

.mysearch /deep/ .el-input__inner {
  height: 55px !important;
  font-size: 15px !important;
  border-radius: 10px !important;
  background: transparent;
}

.imgContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.bg_dw {
  position: absolute;
  left: 21%;
  top: 31%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.bg_eg1 {
  position: absolute;
  left: 34%;
  top: 20%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 1.4s;
}

.bg_eg3 {
  position: absolute;
  left: 50%;
  top: 48%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 2.0s;
}

.bg_eg2 {
  position: absolute;
  left: 26%;
  top: 57%;
  opacity: 0;
  animation: zoomIn 800ms ease;
  animation-fill-mode: forwards;
  animation-delay: 1.8s;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  100% {
    opacity: 1;
  }
}

/deep/ .el-divider__text {
  background: transparent;
}

/deep/ .el-divider {
  background: transparent;
}

/deep/ .el-input .el-input__count .el-input__count-inner {
  background: transparent;
}
</style>
