import { render, staticRenderFns } from "./MyFenData.vue?vue&type=template&id=732b1913&scoped=true"
import script from "./MyFenData.vue?vue&type=script&lang=js"
export * from "./MyFenData.vue?vue&type=script&lang=js"
import style0 from "./MyFenData.vue?vue&type=style&index=0&id=732b1913&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "732b1913",
  null
  
)

export default component.exports