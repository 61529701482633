import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
    menu: {
        home: 'Home',
        Recharge: {
            title: 'Account',
            subMenu: {
                onlineRecharge: 'Top Up',
                myWallet: 'Switch',
                rechargrLogs: 'Record',
                BalanceStatement: 'Bourse',
                withdraw: 'Extract'
            }
        },
        card: {
            title: 'Issuance',
            subMenu: {
                applyCard: 'Issuance',
                myCard: 'My Card ',
                saleLogs: 'Transaction Data',
                Recordofdishonour: 'Manipulate   Data',
            }
        },
        extend: {
            title: 'Alliance',
            subMenu: {
                AlliancePromotion: 'Client',
                SplitDiary: 'Brokerage',
            }
        },
        advertisement: {
            title: 'Business',
            subMenu: {
                CorporateAdvertising: 'Busines',
                EnterpriseCertification: 'Activate',
                AdvertisingAlliance: 'Execute',
            }
        },
        shopping:{
            title: 'Activity',
            tips:"developing......"
        },
        withdraw: {
            title: 'Extract',
        },
        Toggle: {
            title: 'Toggle',
        },
        setting: {
            title: 'Settings',
            subMenu: {
                IndividualCenter: 'Dashboard',
                GoogleBinding: 'Security',
                ChangePassword: 'Password',
                logout: 'Sign Out',
            }
        },
        scanTheCodeOnWeChat: 'Scan wechat'
    },
    userInfos: {
        personalCenter: 'Individual center',
        username: 'Username',
        name: 'Name',
        numberOfInvitedRegistrants: 'Number of guests',
        timeOfRegistration: 'Registration time',
        usdBalance: 'USD balance',
        whetherOrNotYouHaveARealName: 'Authentication',
        numberOfCardsOpened: 'Card Quantity ',
        loginAddress: 'Login address',
        email:"Email",
        cnyBalance: 'CNY balance',
        invitationCode: 'Invitation code',
        rejectionRate: 'Card data',
        freeze: 'Freeze',
        realNameNamed: 'YES',
        noRealName: 'NO',
    },
    googleBinding: {
        pre: ' ',
        content: 'Authenticator',
        after: 'QR Code',
    },
    changePassword: {
        title: 'Reset Password',
        oldPWD: 'Please enter your old password',
        newPWD: 'Please enter your new password',
        rule: 'Passwords can only contain small letters and numbers,{a-z,A-z,0-9}',
        confirm: 'Confirm',
        cancel: 'Cancel',
    },
    welcome: {
        customerServiceWeChat: 'Wechat',
        mobile: 'Mobile',
        announcement: 'Announcement',
        home: 'Home',
        main: 'Main',
        working: 'Online: 10:00-18:30 TM'
    },
    onlineRecharge: {
        title: 'Top Up',
        home: 'Home',
        depositApplication: 'Top-Up',
        depositInUsd: 'Top Up',
        depositFee: 'Top up fee (%):',
        zfbdepositFeedes: 'The fee includes 1% Alipay fee',
        wxdepositFeedes: 'The fee includes 1% wechat fee',
        topUpAmount: 'Amount',
        depositMethod: 'Recharge type',
        crossBorderTopUp: 'Alipay',
        timelyArrival: 'In Time',
        hongKongForBusiness: 'Bank(HK)',
        ustdRecharge: 'Trc-20',
        nextDayArrival: 'Next Day',
        depositCurrency: 'Currency:',
        usd: 'USD',
        theActualAmountReceived: 'Total:',
        notAtThisTime: 'Nonsupport',
        bankTransfer: 'Bank',
        assetOverview: 'Assets',
        rechargeRecord: 'Recharge record',
        assetBalances: 'Balance',
        availableBalance: 'Usable',
        availableBalancedes: 'Available balance = Asset balance - amount recharged during audit',
        freezeTheAmount: 'Frozen amount',
        frequentlyAskedQuestions: 'Q&A',
        des1: 'What if the transaction is still displayed in the transaction or pending payment?',
        des2: "After the transaction is successful, it will take a few seconds to get to the account, wait a few seconds after still not updated, you can refresh the page; If you still haven't received the account after waiting for a long time, you can contact customer service or operations manager.",
        weChatPay: 'Wechat Pay',
        alipayPayment: 'Alipay payment',
        des3: 'Please pay within 2 hours',
        close: 'close',
        submitYourOrder: 'Submit',
    },
    wallet: {
        home: 'Home',
        depositApplication: 'Top-Up',
        renminbi: 'RMB',
        title: 'Wallet',
        availableBalance: 'balance',
        balanceConversion: 'convert',
        des1: 'Exchange rate updates Please refer to the Bank of China exchange rate selling price for details.',
        transferOutOfTheWallet: 'Currency',
        des2: 'Please select an active area',
        usd: 'USD',
        theAmountToBeTransferredOut: 'FX Sell',
        transferToYourWallet: 'Currency',
        transferInAmount: 'FX Buy',
        confirmTheRedemption: 'Sell',
        purse: 'purse',
    },
    transactionHistory: {
        home: 'Home',
        title: 'Record',
        depositApplication: 'Top-Up',
        des4: 'Start date',
        des5: 'End date',
        des6: 'Search',
        des7: 'Recharge channel',
        des8: 'Top up status',
        search: 'search',
        flushed: 'Refresh',
        download: 'Download',
        redemptionHistory: 'Exchange record',
        orderNumber: 'Order number',
        remark: 'remark',
        topUpUsers: 'Top up user',
        creationTime: 'Creation time',
        rechargeChannels: 'channel',
        alipay: 'Alipay',
        wechat: 'wechat',
        bankCards: 'Bank card',
        manualTopUp: 'Manual top-up',
        manualDeductions: 'Labor deduction',
        offshoreTopUp: 'Offshore top-up',
        cryptocurrencies: 'USDT',
        amount: 'amount',
        theEstimatedAmountToBeReceived: 'Settlement',
        theTypeOfDepositCurrency: 'Currency',
        walletBalance: 'balance',
        topUpStatus: 'status',
        waitForPayment: 'Wait',
        success: 'success',
        fail: 'fail',
        successpay: 'successfully',
        failpay: 'fail',
        cancelThePayment: 'cancel',
        theTopUpExpires: 'expired',
        operation: 'operation',
        repay: 'repay',
        cancelThePayment: 'cancel',
        weChatPay: 'weChatPay',
        des1: 'Please pay within 2 hours.',
        close: 'close',
        username: 'username',
        orderId: 'orderId',
        redemptionTime: 'Conversion time',
        spendingAmountCny: 'Amount spent (CNY)',
        exchangeRate: 'Exchange rate',
        exchangeAmountUsd: 'Exchange Amount (USD)',
        redemptionStatus: 'Conversion status',
        theLastWeek: 'Nearly a week',
        theLastMonth: 'About a month',
        lastThreeMonths: 'Last 3 months',
    },
    balanceDetails: {
        home: 'Home',
        title: 'Bourse',
        depositApplication: 'Top-Up',
        des1: 'Search',
        startDate: 'Start date',
        endDate: 'End date',
        des2: 'Search',
        des3: 'Search',
        search: 'search',
        export: 'export',
        flushed: 'Refresh',
        cardNumber: 'Card number',
        theUserToWhomItBelongs: 'Owning user',
        operators: 'operator',
        operatingTime: 'Operating time',
        operationalEvents: 'Operation event',
        cardTopUp: 'Card Top-up',
        theCardIsTransferredOut: 'Card roll-out',
        signUpFee: 'Card activation fee',
        cancellationCardTransfer: 'Card cancellation transfer',
        revokeTheTransferIn: 'Undo transfer',
        insufficientBalanceIsDeducted: 'Underbalance',
        crossBorderFees: 'Cross-border commission',
        retroactiveHandlingFee: 'Back payment commission',
        refundProcessingFee: 'Refund commission',
        cardTransactionFees: 'Card transaction fee',
        advertisingFeePayment: 'Advertising fee',
        advertisingFeeRefund: 'Advertising refund',
        paymentStatus: 'Status',
        success: 'Success',
        fail: 'Fail',
        amount: 'amount',
        walletBalance: 'Wallet balance (USD)',
        usdRecords: 'Dollar record',
        theLastWeek: 'Nearly a week',
        theLastMonth: 'About a month',
        lastThreeMonths: 'Last 3 months',
    },
    applyCard: {
        home: 'Home',
        title: 'Issuance',
        cardManagement: 'Card',
        des1: 'Screening Card Bin',
        cardStatus: 'Card status',
        cardNumberSegment: 'Card section',
        cardDecks: 'deck',
        region: 'region',
        cardCurrency: 'Currency',
        customizeYourBillingAddress: 'Billing',
        signUpFunction: 'Activate',
        rechargeFunction: 'top-up',
        rolloutFunction: 'roll-out',
        cancelTheCardFunction: 'Cancel',
        freezeFunction: 'Frozen',
        numberOfCardsOpened: 'quantity',
        signUpFee: 'activation fee',
        des2: 'Please contact the account manager to adjust',
        des3: 'Top up fee (%)',
        cardDescription: 'Description',
        operation: 'operation',
        yes: 'Available',
        notAvailable: 'Not available',
        des4: 'Contact the account manager for permission',
        apply: 'Apply',
        applyForACard: 'Application card',
        FcardNumber: 'Card number:',
        FcardNumberSegment: 'Card section:',
        FexpirationDate: 'validity:',
        FcardCurrency: 'Currency:',
        FcardDescription: 'Description:',
        FsignUpFee: 'activation fee:',
        FdepositFee: 'Top-up fee:',
        FbalanceUsd: 'Balance (USD):',
        FbalanceCny: 'Balance (CNY):',
        FnumberOfApplications: 'quantity:',
        FtopUpAmount: 'amount',
        FtotalSpending: 'total',
        Fcancel: 'cancel',
        Fconfirm: 'confirm',
        Fdes5: 'It is recommended to charge more than 1-2 US dollars to prevent payment failure.',
    },
    queryCard: {
        home: 'Home',
        title: 'My Card',
        cardManagement: 'Card',
        des1: 'Search',
        creationTime: 'Creation time',
        cardStatus: 'Card status',
        search: 'search',
        flushed: 'Refresh',
        bulkRecharge: 'Bulk recharge',
        downloadTheCard: 'Download',
        normalCard: 'normal',
        numberOfDownloads: 'number',
        operation: 'operation',
        cardInformation: 'information',
        cardNumber: 'cardNumber',
        currency: 'currency',
        creationTime: 'Creation time',
        cardStatus: 'Card status',
        normal: 'normal',
        theCardIsLocked: 'Locked card',
        cancelledCard: 'Cancelled card',
        cardNotes: 'remark',
        des2: 'Please enter the card remarks',
        cardBalance: 'Card balance',
        synchronous: 'Update',
        operation: 'operation',
        recharge: 'top-up',
        cancellationOfTheCard: 'Cancel a card',
        rollOut: 'roll-out',
        cardDetails: 'Card details',
        showCardDetails: 'Copy, display card details',
        month: 'm',
        year: 'y',
        consumptionHistory: 'Consumption record',
        flushed: 'Refresh',
        refundRecordInquiry: 'Refund record inquiry',
        updateTheAddress: 'Update address',
        cardNumber: 'Card number',
        theNameOfTheBusiness: 'Merchant name',
        orderTime: 'Order time',
        theAmountSpent: 'Amount',
        theTypeOfTransaction: 'type',
        transactionStatus: 'status',
        transactionDescription: 'description',
        theCurrencyOfTheTransaction: 'currency',
        updateYourBillingAddress: 'Update billing address',
        countryCode: 'Country code',
        provinceState: 'Province/state',
        city: 'City',
        zip: 'zip code',
        fullAddress: 'Detailed address',
        cancel: 'cancel',
        confirm: 'confirm',
        cardTopUp: 'Card recharge',
        rechargeCardNumber: 'card number',
        cardBalance: 'balance',
        cardNumberCurrency: 'currency',
        walletBalanceUsd: 'balance',
        cardRechargeFee: 'fee %',
        topUpAmount: 'amount',
        estimatedCost: 'budget',
        rechargeRemarks: 'remark',
        des3: 'It is recommended to charge more than 1-2 US dollars to prevent payment failure.',
        theCardNumberIsTransferredOut: 'roll-out',
        transferOutCardNumber: 'Card number',
        cardNumberBalance: 'balance',
        theAmountToBeTransferredOut: 'amount',
        rollOutTheNote: 'remark',
        refundHistory: 'Refund record',
        cardNumber: 'Card number',
        billingCurrency: 'Bill currency',
        transactionStatus: 'status',
        theRefundAuthorizationWasSuccessful: 'Authorization succeeds',
        refundAuthorizationFailed: 'Authorization failure',
        refundAuthorization: 'Under authorization',
        punchTheRightSuccess: 'Correct and succeed',
        failedToStraighten: 'Shock failure',
        billAmount: 'amount',
        liquidationTime: 'Clearing time',
        theNameOfTheBusiness: 'Merchant name',
        remark: 'remark',
        bulkRecharge: 'Bulk recharge',
        walletBalanceUsd: 'balance',
        numberOfTopUpCards: 'quantity',
        cardRechargeFee: 'fee %',
        topUpAmount: 'amount',
        estimatedCost: 'budget',
        rechargeRemarks: 'remark',
    },
    saleType: {
        consume: 'consumption',
        correctionOfAuthorization: 'Corrective authorization',
        verify: 'verify',
        quash: 'cancel',
        refund: 'refund',
        validRefunds: 'Validity refund',
        recharge: 'top-up',
        cardBalanceRefund: 'Card balance refund',
        theCancelledCardIsReturned: 'Cancellation and return',
        serviceCharge: 'Service charge',
        revocationOfRefunds: 'Refund cancellation',
        authorizedConsumption: 'Authorized consumption',
        refund: 'refund',
        theCardIsTransferredOut: 'roll-out',
        cardTransferIn: 'Transfer to',
    },
    saleStatus: {
        theTransactionWasSuccessful: 'Successful',
        theTransactionFailed: 'failed',
        trading: 'In transaction',
        theTransactionReversalIsSuccessful: 'Undo successfully',
        transactionReversalFailed: 'Undo failure',
    },
    businessHistory: {
        home: 'Home',
        title: 'Card Data',
        cardManagement: 'Card',
        des1: 'Search',
        des2: 'Search',
        des3: 'Search',
        startDate: 'Start date',
        endDate: 'End date',
        search: 'search',
        flushed: 'Refresh',
        download: 'download',
        username: 'username',
        operationalEvents: 'Operation event',
        cardNumber: 'Card number',
        theNameOfTheMerchant: 'Merchant name',
        tradingHours: 'Trading hours',
        theAmountOfTheTransaction: 'amount',
        theCurrencyOfTheTransaction: 'currency',
        transactionStatus: 'status',
        transactionDescription: 'description',
        theLastWeek: 'Nearly a week',
        theLastMonth: 'About a month',
        lastThreeMonths: 'Last 3 months',
    },
    chargebackHistory: {
        home: 'Home',
        title: 'Failure Data',
        cardManagement: 'Card',
        rejectionRate: 'Repudiation rate',
        failedAmount: 'Amount of failure',
        clampHeaders: 'chuck',
        theTotalNumberOfTransactions: 'Total',
        numberOfFailures: 'Number of failures',
        theNumberOfChargebacks: 'Number of refused',
        theTotalAmountSpent: 'Total amount',
        failedAmount: 'Amount of failure',
        theAmountOfTheChargeback: 'Amount protested',
        updated: 'Update time',
    },
    affiliatePromotion: {
        home: 'Home',
        title: 'Client',
        des1: 'Search',
        username: 'username',
        timeOfRegistration: 'Registration time',
        invitationCode: 'Invitation code',
        userStatus: 'User status',
        normal: 'normal',
        deactivated: 'Out of service',
        toBeReviewed: 'To be reviewed',
        notPassed: 'Not go',
        operation: 'operation',
        rechargeHistory: 'Recharge record',
        inquireAboutTheCard: 'Enquiry card',
        turnoverRecords: 'Flow record',
        des2: 'Please enter the card number',
        rechargeChannels: 'Recharge channel',
        startDate: 'Start date',
        endDate: 'End date',
        search: 'search',
        brushNew: 'Refresh',
        orderNumber: 'Order number',
        theUserToWhomItBelongs: 'Owning user',
        operators: 'operator',
        operatingTime: 'Operating time',
        rechargeChannels: 'Recharge channel',
        alipay: 'alipay',
        wechat: "wechat",
        bankCards: 'Bank card',
        manualTopUp: 'Labour',
        manualDeductions: 'Labor deduction',
        offshoreTopUp: 'Offshore top-up',
        cryptocurrencies: 'USDT',
        theTypeOfDepositAmount: 'Recharge amount type',
        theAmountReceived: 'Amount received',
        remark: 'remark',
        topUpStatus: 'Top up status',
        waitForPayment: 'Wait for payment',
        success: 'Successful',
        fail: 'Lose',
        cancelThePayment: 'Cancel payment',
        queryCards: 'Inquiry card',
        des3: 'Please enter the card number',
        dateOfApplication: 'Application time',
        cardStatus: 'Card status',
        cardTransactionHistory: 'record',
        cardNumber: 'Card number',
        theNameOfTheBusiness: 'Merchant name',
        orderTime: 'Order time',
        theAmountSpent: 'Amount of consumption',
        theTypeOfTransaction: 'Transaction type',
        transactionStatus: 'Transaction status',
        transactionDescription: 'Transaction description',
        theCurrencyOfTheTransaction: 'Transaction currency',
        cardType: 'type',
        shareCards: 'Shared card',
        cardNumber: 'Card number',
        currency: 'currency',
        signUpDate: 'Date of card issuance',
        cardStatus: 'Card status',
        normal: 'normal',
        locked: 'locked',
        cancellationOfTheCard: 'Cancel a card',
        availableBalance: 'Available balance',
        cardNotes: 'Card remark',
        productBtn:"Product config",
        priceBtn:"Price config",
        cardConfig:"Card segment",
        plantag:"plane ticket",
        addtag:"advertisement",
        shoptag:"online retailers",
        apptag:"subscribe",
        othertag:"other",
        comfirmBtn:"Modify",
        priceConfig:"Price configuration",
        production:"product",
        isUserSetConfig:"Based on user settings",
        cardFee:"card Fee",
        rechargeFee:"Recharge Fee",
        changeBtn:"Modify",
        tips:"Insufficient Permissions！"
    },
    partitioninglogs: {
        home: 'Home',
        title: 'Brokerage',
        des1: 'username',
        des2: 'Search',
        startDate: 'Start date',
        endDate: 'End date',
        search: 'search',
        brushNew: 'Refresh',
        topUpYourUsername: 'username',
        theAmountReceivedAfterTheTopUp: 'Amount received',
        itIsDividedIntoCurrencyTypes: 'Currency type',
        theTypeOfTransaction: 'Transaction type',
        agentsShared: 'sharing',
        userWithdrawalDeduction: 'Withdrawal deduction',
        splitAmount: 'Share amount',
        divideTheTotalBalance: 'Overall balance',
        splitExchangeRate: 'Share rate',
        divideIntoTime: 'Split into time',
    },
    gg: {
        home: 'Home',
        title: 'Business',
        des1: 'Please select any media to open an advertising account and start your voyage',
        des11: "The world's largest social network with 2.89 billion monthly active users",
        des2: 'If you are new to overseas social media marketing, Facebook is recommended',
        des3: 'No matter who your customers are, you can find them',
        corporateAccountOpening: 'open an account',
        des4: 'One of the most popular short video social platforms in the world',
        des5: 'It reaches over 1 billion users in more than 150 countries',
        des6: 'It is suitable for customers aged 18-24 with interactive preference',
        des7: "The world's largest search engine company, covering 90% of the world's Internet users",
        des8: 'Familiar with user shopping preferences, tracking behavior trajectory',
        des9: 'Match your customers with keyword searches',
        des10: 'Wechat scan code add manager account opening.',
        cancel: 'Cancel',
    },
    enterpriseCertification: {
        home: 'Home',
        title: 'Activate',
        applyForCertification: 'Apply for certification',
        certificationAudits: 'Certification audit',
        auditResults: 'Audit result',
        typeOfBusiness: 'Type of enterprise',
        des1: 'Please select a business type',
        hongKongEnterprises: 'Hong Kong enterprises',
        chineseEnterprises: 'Chinese enterprise',
        theNameOfTheBusiness: 'Company name',
        certificateOfIncorporation: 'licence',
        des2: 'Only one photo: PNG,JPG,JPEG: maximum 10MB',
        reviewStatus: 'Audit status',
        passed: 'Already passed',
        reject: 'Turn down',
        application: 'Apply',
        recertification: 'recertification',
        des3: 'During the certification audit, we may need to complete the review of your data within 1-3 days at the earliest. Please be patient......',
        des4: 'Please select a business name',
        hongKongEnterprises: 'Hong Kong enterprises',
        chineseEnterprises: 'Chinese enterprise',
        uSBusinesses: 'American enterprise',
        btn:"Upload",
        tips:"PDF文件(要求上传登记证书、注册证书、法团成立表格)"
    },
    eCommerceCustomerAcquisition: {
        home: 'Home',
        title: 'Advertising',
        des1: 'Please enter the advertising password - identification number',
        search: 'search',
        brushNew: 'Refresh',
        applyForAnAdvertisement: 'Apply',
        reviewStatus: 'Audit status',
        approved: 'Pass the examination',
        underReview: 'Under review',
        reviewRejection: "Review and reject",
        taskStatus: "Task status",
        didnTStart: 'not started',
        screenshotOfTheTask: 'Task screenshot',
        pause: 'pause',
        comeToAnEnd: 'finish',
        passwordIdentificationNumber: 'code',
        deliveryMode: 'Release mode',
        flowRate: 'Flow rate',
        sales: 'Sales volume',
        theTypeOfImpression: 'Display type',
        site: 'website',
        informationApplications: 'Information application',
        targetStrategy: 'Target strategy',
        maximizeConversions: 'Conversion maximization',
        maximizeConversionValue: 'Conversion value maximization',
        startDate: 'Start date',
        startTime: 'Start time',
        endDate: 'End date',
        endTime: 'End time',
        deliveryBudget: 'Release budget',
        numberOfDaysForDuration: 'Duration of days',
        totalBudget: 'General budget',
        totalCost: 'Total cost',
        country: 'Country of delivery',
        launchCity: 'Put into the city',
        ageOfTheTargetGroup: 'Age of target population',
        genderOfTheTargetGroup: 'Target population gender',
        language: 'language',
        adHeadline: 'Advertising title',
        descriptionOfTheAd: 'Advertising description',
        advertisingImages: 'Advertising picture',
        theUrlOfTheAdvertisingWebsite: 'Advertising website URL',
        whatsAppContacts: 'WhatsApp Contacts',
        creationTime: 'Creation time',
        updated: 'Update time',
        operation: 'operation',
        des2: 'Editing is only allowed if the review is rejected',
        editorial: 'editorial',
        des3: 'Put your brand on',
        des4: 'To a wider market!',
        des5: 'Release mode',
        des6: 'Attract more user traffic to your website or app, increasing brand awareness and exposure.',
        des7: 'Drive product sales, increase conversions and order volumes to achieve more immediate business goals.',
        theTypeOfImpression: 'Display type',
        websiteDelivery: 'Website delivery',
        appInformation: 'Application information',
        targetStrategy: 'Target strategy',
        pleaseSelectATargetPolicy: 'Please select the target policy',
        des8: 'Ads generate the most conversions, such as click to buy, submit forms, or other conversions you define. This means that more users are guided by the advertisement to perform the expected action, improving the effectiveness of the advertisement.',
        maximizeConversions: 'Conversion maximization',
        des9: "Unlike the number of conversions alone, the conversion value takes into account the quality and value of each conversion. For example, the value of a user's purchase of a higher-priced item is higher than that of a lower-priced item. In this case, AD optimization focuses on improving the quality and value of each conversion.",
        maximizeConversionValue: 'Conversion value maximization',
        budgetForAdServing: 'Advertising budget',
        advertisingBudget: 'Advertising budget',
        premium: 'Handling charge',
        totalBudget: 'General budget',
        balance: 'balance',
        theNumberOfDaysTheAdHasBeenRunning: 'AD days',
        des10: 'Please enter the number of days',
        startTime: 'Please select a start time',
        des11: 'Please select a start date',
        des12: 'Please select a start time',
        endTime: 'End time',
        endDate: 'End date',
        region: 'Drop area',
        des13: 'Please enter the country in English',
        des14: 'Please enter the country of release',
        des15: 'Please enter the city in English',
        des16: 'Please enter the release city',
        genderOfTheTargetGroup: 'Target group gender',
        des17: 'Please select the target group',
        random: 'Random',
        man: 'man',
        woman: 'Woman',
        des18: 'Age of target group',
        des19: 'The target group is 18-35 years old',
        deliveryLanguage: 'Throw language',
        des20: 'Please enter language',
        adHeadline: 'Advertising title',
        des21: 'Please enter the AD title',
        descriptionOfTheAd: 'Advertising description',
        des22: 'Please enter the advertisement description',
        serveYourWebsite: 'Launch website',
        des23: 'Please enter the launch website',
        contact: 'Contact information',
        des24: 'Please enter your whatsapp contact information',
        previous: 'Last step',
        next: 'next',
        apply: 'Apply',
        cancel: 'Cancel',
        eCommerceGoesOverseas: 'Overseas e-commerce',
    },
    balanceWithdrawals: {
        home: 'Home',
        title: 'Extract',
        walletBalance: 'Wallet balance',
        payouts: 'withdraw',
        withdrawalsAreFrozen: 'Frozen',
        withdrawalExchangeRate: 'Exchange rate',
        orderNumber: 'Order number',
        withdrawalUsername: 'username',
        theAmountToBeWithdrawn: 'Withdrawal amount',
        withdrawalCurrency: 'Currency',
        payoutStatus: 'status',
        underReview: 'Under review',
        approved: 'pass',
        reviewRejection: 'Turn down',
        alipayAccount: 'Alipay account',
        alipayName: 'Alipay name',
        withdrawalRequestRemarks: 'remark',
        approvalNotes: 'Audit remarks',
        underReview: 'Under review',
        reviewTime: 'Audit time',
        withdrawalTime: 'Withdrawal time',
        withdrawalExchangeRate: 'Withdrawal rate',
        payoutStatus: 'Withdrawal status',
        theCurrencyOfTheTransaction: 'Transaction currency',
        search: 'search',
        flushed: 'Refresh',
        withdrawalRequests: 'Withdrawal application',
        withdrawalsAreFrozen: ' ',
        withdrawalExchangeRate: 'Withdrawal rate',
        orderNumber: 'Order number',
        withdrawalUsername: 'user name',
        theAmountToBeWithdrawn: 'amount',
        withdrawalCurrency: 'Currency',
        payoutStatus: 'status',
        underReview: 'Under review',
        approved: 'pass',
        reviewRejection: 'Turn down',
        alipayAccount: 'Alipay account',
        alipayName: 'Alipay name',
        withdrawalRequestRemarks: 'remark',
        approvalNotes: 'Audit remarks',
        reviewTime: 'Audit time',
        withdrawalTime: 'Withdrawal time',
        withdrawalExchangeRate: ' ',
        withdrawalRequests: 'Withdrawal application',
        dollar: 'dollar',
        balance: 'balance',
        theAmountReceived: 'received',
        submitAnApplication: 'Submit',
        theLastWeek: 'Nearly a week',
        theLastMonth: 'About a month',
        lastThreeMonths: 'Last 3 months',
    },
    login: {
        logInToYourInstantAccount: 'Sign in to your Live account',
        signUpForAnAccount: "Register an account",
        ForgotPassword:'Forgot password',
        des1: 'Username',
        des2: 'Password',
        des3: 'Code',
        des4: 'Code',
        login: 'Log In',
        otherWays: 'Other Ways',
        googleLogin: 'Google Login',
        accountLogin: 'Account Login',
        coderule: 'The verification code cannot be empty',
        userrule: 'The username cannot be empty',
        pwdrule: 'Password cannot be empty',
        lengthrule: 'Length between 5 and 12 characters',
        abcrule: 'Passwords can only contain uppercase and lowercase letters and numbers, {a-z, A-z, 0-9}',
        ggrule: 'The length of the verification code is 6'
    },
    forgot:{
        title:"Retrieve password",
        backLogin:"Back to login",
        signUpForAnAccount: 'Sign in',
        des1: 'Username',
        des2:'Email',
        des3: 'Code',
        des4: 'Password',
        des5: 'Password',
        submit:'verify',
        coderule: 'The verification code cannot be empty',
        userrule: 'The username cannot be empty',
        pwdrule: 'Password cannot be empty',
        lengthrule: 'Length between 5 and 12 characters',
        abcrule: 'Passwords can only contain uppercase and lowercase letters and numbers, {a-z, A-z, 0-9}',
        emailrule: 'Please enter your email address',
        sendBtn:"send",
    },
    register: {
        signUpForYourInstantAccount: 'Register your instant account',
        country: 'Nation',
        des1: ' Phone',
        des2: 'Password',
        des3: 'Confirm password',
        des4: 'Invitation code',
        des5: 'Name',
        des6: 'Valid identity documents ID',
        des7: 'Code',
        des8: 'The two password inputs are inconsistent',
        des9: 'Email',
        enroll: 'Sign In',
        readAndAgree: 'Agree',
        registrationAgreement: 'RA',
        privacyAgreement: 'Privacy Policy',
        refundAgreement: 'Confidentiality Terms',
        logInNow: 'Sign In Now',
        userrule: 'The username cannot be empty',
        userLengthrule: 'The length is 11 characters',
        pswrule: 'Password cannot be empty',
        pswLengthrule: 'Length between 5 and 15 characters',
        pswabcrule: 'Passwords can only contain uppercase and lowercase letters and numbers, {a-z, A-z, 0-9}',
        pswAgainrule: 'The two password inputs are inconsistent',
        codeurule: 'Invitation code must be filled in',
        namerule: 'Name must be filled in',
        chineserule:'Chinese must be filled in',
        idrule: 'ID number must be filled in',
        
    },
    globalpage: {
        footer: ' '
    },
    ...enLocale
}
// 导出
export default en;
